import { format, getDaysInMonth, getMonth, getYear, parse, subMilliseconds } from 'date-fns';
import { fromZonedTime, getTimezoneOffset, toZonedTime } from 'date-fns-tz';

export function getTimezoneAbbreviation(timeZone: string) {
  // Create a date object with the timezone's local time
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short',
  }).formatToParts(new Date());

  // Find the timezone abbreviation part
  const timeZoneAbbr = formattedDate.find((part) => part.type === 'timeZoneName');

  return timeZoneAbbr ? timeZoneAbbr.value : 'Unknown Timezone';
}

export function localTimeToUtc(date: Date) {
  return toZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function utcToLocalTime(date: Date) {
  return fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function utcToTimeZone(date: Date, timeZone: string) {
  const offsetToTimeZone = getTimezoneOffset(timeZone, date);

  return subMilliseconds(date, offsetToTimeZone);
}

export function formatZonedTime(date: Date, formatPattern: string, timeZone: string) {
  date = fromZonedTime(date, timeZone);

  return format(date, formatPattern);
}

export function parseDateStringAsTimeZone(dateStr: string, format: string, timeZone: string, modification?: (date: Date) => Date) {
  let date = parse(dateStr, format, new Date());

  if (modification) {
    date = modification(date);
  }

  return fromZonedTime(date, timeZone);
}

export function getFiscalYear(date: Date, fyEndMonth: number, timeZone?: string) {
  if (timeZone) {
    date = toZonedTime(date, timeZone);
  } else {
    date = localTimeToUtc(date);
  }

  const year = getYear(date);
  const month = getMonth(date) + 1; // +1 because getMonth() returns months in the range 0-11.

  if (month <= fyEndMonth) {
    return String(year);
  }

  // If the date's month is after the fiscal year ending month, then return the next year.
  return String(year + 1);
}

export function getFiscalYearStart(date: Date, fyEndMonth: number, timeZone?: string) {
  const fy = getFiscalYear(date, fyEndMonth, timeZone);

  return getFiscalYearStartForFY(fy, fyEndMonth, timeZone);
}

export function getFiscalYearStartForFY(fy: string, fyEndMonth: number, timeZone?: string) {
  const fyNum = Number(fy);

  const fyStartMonth = fyEndMonth === 12 ? 1 : fyEndMonth + 1;
  const fyStartYear = fyEndMonth < 12 ? fyNum - 1 : fyNum;

  const utcDate = new Date(Date.UTC(fyStartYear, fyStartMonth - 1, 1));

  if (timeZone) {
    return utcToTimeZone(utcDate, timeZone);
  } else {
    return utcToTimeZone(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
}

export function getFiscalYearEnd(date: Date, fyEndMonth: number, timeZone?: string) {
  const fy = getFiscalYear(date, fyEndMonth, timeZone);

  return getFiscalYearEndForFY(fy, fyEndMonth, timeZone);
}

export function getFiscalYearEndForFY(fy: string, fyEndMonth: number, timeZone?: string) {
  const fyNum = Number(fy);

  const midMonth = new Date(Date.UTC(fyNum, fyEndMonth - 1, 10));

  const daysInMonth = getDaysInMonth(midMonth);

  const utcDate = new Date(Date.UTC(fyNum, fyEndMonth - 1, daysInMonth, 23, 59, 59, 999));

  return timeZone ? utcToTimeZone(utcDate, timeZone) : utcToTimeZone(utcDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function dateIsWithinFY(date: Date, fy: string, fyEndMonth: number, timeZone: string) {
  const start = getFiscalYearStartForFY(fy, fyEndMonth, timeZone);
  const end = getFiscalYearEndForFY(fy, fyEndMonth, timeZone);

  return start <= date && date <= end;
}

export function getDateString(date: Date) {
  return format(date, 'yyyy-MM-dd');
}
