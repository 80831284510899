import { CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Eye, EyeSlash, TickCircle } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../api';
import { Button, Link, Logo } from '../../components';

function ResetPasswordForm({ onResetSuccess }: { onResetSuccess: () => void }) {
  const theme = useTheme();
  const { token } = useParams();

  const { resetPassword } = useSession();
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [revealPassword, setRevealPassword] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const passwordError = useMemo(() => {
    if (!password) {
      return 'Password is required.';
    } else if (password.length < 12) {
      return 'Password must have at least 12 characters.';
    } else if (!/[0-9]/g.test(password)) {
      return 'Password must contain a number.';
    } else if (!/[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(password)) {
      return 'Password must contain a special character.';
    } else {
      return null;
    }
  }, [password]);
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [revealRepeatedPassword, setRevealRepeatedPassword] = useState(false);
  const [repeatedPasswordTouched, setRepeatedPasswordTouched] = useState(false);
  const repeatedPasswordError = useMemo(() => {
    if (!repeatedPassword) {
      return 'Please repeat your password.';
    } else if (repeatedPassword !== password) {
      return 'Passwords do not match.';
    } else {
      return null;
    }
  }, [password, repeatedPassword]);

  const submit = async () => {
    if (!!passwordError || !!repeatedPasswordError || loading) {
      return;
    }

    setLoading(true);

    try {
      await resetPassword(token!, password);
      onResetSuccess();
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit}>
      <Stack alignItems='center'>
        <Typography variant='h3'>Reset Password</Typography>
        <Typography>Enter a new password for your account.</Typography>
        <FormControl required error={!!passwordError && passwordTouched} style={{ marginTop: theme.spacing(5) }}>
          <TextField
            label='Password'
            type={revealPassword ? 'text' : 'password'}
            placeholder='Enter your password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => setPasswordTouched(true)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton tabIndex={-1} onClick={() => setRevealPassword((revealPassword) => !revealPassword)}>
                    {revealPassword ? <EyeSlash /> : <Eye />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!!passwordError && passwordTouched && <FormHelperText>{passwordError}</FormHelperText>}
        </FormControl>
        <FormControl required error={!!repeatedPasswordError && repeatedPasswordTouched} style={{ marginBottom: theme.spacing(5) }}>
          <TextField
            label='Repeat Password'
            type={revealRepeatedPassword ? 'text' : 'password'}
            placeholder='Repeat your password'
            value={repeatedPassword}
            onChange={(e) => setRepeatedPassword(e.target.value)}
            onBlur={() => setRepeatedPasswordTouched(true)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton tabIndex={-1} onClick={() => setRevealRepeatedPassword((revealRepeatedPassword) => !revealRepeatedPassword)}>
                    {revealRepeatedPassword ? <EyeSlash /> : <Eye />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!!repeatedPasswordError && repeatedPasswordTouched && <FormHelperText>{repeatedPasswordError}</FormHelperText>}
        </FormControl>
        <Button type='submit' variant='contained' color='primary' disabled={!!passwordError || !!repeatedPasswordError || loading} onClick={submit}>
          {loading ? <CircularProgress /> : 'Reset Password'}
        </Button>
      </Stack>
    </form>
  );
}

function ResetConfirmation() {
  const theme = useTheme();
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/?modal=sign-in');
  };

  return (
    <form onSubmit={goToSignIn}>
      <Stack alignItems='center'>
        <TickCircle variant='Bold' color={theme.palette.primary.main} size={theme.spacing(10)} />
        <Typography variant='h3'>Password Reset Successfully</Typography>
        <Typography>You have successfully reset your password. Please sign in to access your account.</Typography>
        <Button type='submit' variant='contained' color='primary' onClick={goToSignIn}>
          Sign in
        </Button>
      </Stack>
    </form>
  );
}

export function ResetPasswordPage() {
  const theme = useTheme();
  const [stage, setStage] = useState<'reset' | 'confirmation'>('reset');

  const content = stage === 'reset' ? <ResetPasswordForm onResetSuccess={() => setStage('confirmation')} /> : <ResetConfirmation />;

  return (
    <Stack width='100vw' height='100dvh' alignItems='center' justifyContent='center'>
      <Link to='/'>
        <Logo style={{ height: theme.spacing(10) }} />
      </Link>

      <Stack bgcolor={theme.palette.background2.default} borderRadius={theme.roundedCorners(8)} alignItems='center' padding={theme.spacing(10)}>
        {content}
      </Stack>
    </Stack>
  );
}
