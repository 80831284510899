import { CheckCircle } from '@mui/icons-material';
import { alpha, Chip, Collapse, Fade, Link, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { ArrowRight, CloseCircle, TickCircle } from 'iconsax-react';
import { CSSProperties, forwardRef, KeyboardEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
  Account,
  deserializeDocument,
  Document,
  documentTotal,
  Organization,
  SerializedDocument,
  StandardAccounts,
  useConversation,
  useDocument,
  useLedger,
  useOrganization,
  User,
  useSession,
} from '../../../api';
import { useCurrentConversation } from '../../../pages/user/current-conversation-context';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { getFiscalYear } from '../../../utils/date-utils';
import { ScreenSize, useScreenSize } from '../../../utils/use-screen-size';
import { DelayCollapse, DelayFade } from '../../animations';
import { Button } from '../../button';
import { DocumentImg } from '../../document-img';
import { OutlineContainer, OutlineContainerSection, useOutlineContainerBorder } from '../../outline-container';
import { ProgressBar } from '../../small-progress-bar';
import { IWidgetProps } from '../IWidgetProps';
import { DocumentImageDialog, restrictLength } from '../shared';

enum ApprovalResult {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

const useConversationData = () => {
  const { data } = useCurrentConversation();
  const { organization } = useSession();
  const { fetchAccounts, fetchJournals, journals, accounts } = useLedger();

  useEffect(() => {
    fetchJournals().catch((e) => {
      throw e;
    });
  }, [fetchJournals]);

  const formattedData = useMemo<null | {
    reimbursementDocuments: Document[];
    documentsApproved: string[];
    documentsDenied: string[];
  }>(() => {
    if (data === null) {
      return null;
    }

    const castedData = data as {
      reimbursementDocuments: SerializedDocument[];
      documentsApproved: string[];
      documentsDenied: string[];
    };

    return {
      ...castedData,
      reimbursementDocuments: castedData.reimbursementDocuments.map(deserializeDocument),
    };
  }, [data]);

  useEffect(() => {
    if (!formattedData || !journals || !organization) {
      return;
    }

    const fys = new Set<string>();
    for (const doc of formattedData.reimbursementDocuments) {
      if (doc.date) {
        fys.add(getFiscalYear(doc.date, organization.fyEndMonth, organization.timeZone));
      }
    }

    for (const fy of fys) {
      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        continue;
      }

      fetchAccounts(journal.id).catch((e) => {
        throw e;
      });
    }
  }, [journals, formattedData, organization, fetchAccounts]);

  const conversationData = useMemo<null | {
    documentsApproved: string[];
    documentsDenied: string[];
    reimbursementDocuments: (Document & { account: Account | null; possiblePaymentAccounts: Account[] })[];
  }>(() => {
    if (!formattedData || !organization || !journals) {
      return null;
    }

    const getAccount = (document: Document) => {
      if (!document.paymentDate && !document.date) {
        return null;
      }

      const documentPaymentDate = document.paymentDate ? document.paymentDate : document.date!;
      const fy = getFiscalYear(documentPaymentDate, organization.fyEndMonth, organization.timeZone);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        return null;
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return null;
      }

      let matches: Account[];
      if (document.paymentAccountNumber && document.paymentCardType) {
        matches = fyAccounts.filter(
          (a) =>
            a.externalMaskedAccountId &&
            document.paymentAccountNumber!.includes(a.externalMaskedAccountId) &&
            (!a.externalType || a.externalType === document.paymentCardType)
        );
      } else if (document.paymentAccountNumber) {
        matches = fyAccounts.filter((a) => a.externalMaskedAccountId && document.paymentAccountNumber!.includes(a.externalMaskedAccountId));
      } else if (document.paymentCardType) {
        matches = fyAccounts.filter((a) => document.paymentCardType === a.externalType);
      } else {
        return null;
      }

      if (matches.length !== 1) {
        return null;
      }

      return matches[0];
    };

    const getPossiblePaymentAccounts = (document: Document) => {
      if (!document.paymentDate && !document.date) {
        return [];
      }

      const documentPaymentDate = document.paymentDate ? document.paymentDate : document.date!;
      const fy = getFiscalYear(documentPaymentDate, organization.fyEndMonth, organization.timeZone);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        return [];
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return [];
      }

      return fyAccounts.filter(
        (a) =>
          a.standardAccount === StandardAccounts.CASH ||
          a.standardAccount === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
          a.standardAccount === StandardAccounts.LINE_OF_CREDIT
      );
    };

    const reimbursementDocuments: (Document & { account: Account | null; possiblePaymentAccounts: Account[] })[] = [];
    for (const doc of formattedData.reimbursementDocuments) {
      const account = getAccount(doc);
      const possiblePaymentAccounts = getPossiblePaymentAccounts(doc);

      reimbursementDocuments.push({
        ...doc,
        account,
        possiblePaymentAccounts,
      });
    }

    return {
      documentsApproved: formattedData.documentsApproved,
      documentsDenied: formattedData.documentsDenied,
      reimbursementDocuments,
    };
  }, [formattedData, accounts, organization, journals]);

  return conversationData;
};

const useOrganizationData = () => {
  const { organization } = useSession();

  const { users, fetchUsers, inviteUser } = useOrganization();

  const orgUsersById = useMemo(() => {
    if (!users) {
      return null;
    }

    return users.reduce(
      (map, current) => {
        map[current.id!] = current;
        return map;
      },
      {} as { [userId: string]: User }
    );
  }, [users]);

  useEffect(() => {
    fetchUsers().catch((e) => {
      throw e;
    });
  }, [organization, fetchUsers]);

  return {
    organization,
    orgUsersById,
    inviteUser,
  };
};

const DocumentPlaceholder = forwardRef<HTMLDivElement>(({ style }: { style?: CSSProperties }, ref) => {
  const outlineContainerBorder = useOutlineContainerBorder();
  const randomSeed = useRef(Math.random());
  const theme = useTheme();

  return (
    <OutlineContainer ref={ref} background={theme.palette.background.default} style={style}>
      <OutlineContainerSection borderBottom={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton variant='rounded' width={32} height={32} />

        <Stack spacing={0} flex={1}>
          <Skeleton variant='text' width={128 + randomSeed.current * 128} />
          <Skeleton variant='text' width={128 + randomSeed.current * 256} />
        </Stack>

        <Stack spacing={0}>
          <Skeleton variant='text' width={64} />
          <Skeleton variant='text' width={64} />
        </Stack>
      </OutlineContainerSection>

      <OutlineContainerSection paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton width={64} />
        <Skeleton width={96} />
      </OutlineContainerSection>
    </OutlineContainer>
  );
});

enum DocumentApprovalState {
  APPROVAL = 'APPROVAL',
  COMPLETE = 'COMPLETE',
}

const useDocumentApprovalState = ({ loading, result }: { loading: boolean; result: ApprovalResult | null }) => {
  const [prevState, setPrevState] = useState<DocumentApprovalState | null>(null);
  const [state, setState] = useState<DocumentApprovalState | null>(null);

  useEffect(() => {
    setPrevState(state);
  }, [state]);

  const nextDisabled = useMemo(() => {
    if (loading) {
      return true;
    } else if (state === DocumentApprovalState.APPROVAL) {
      return !result;
    } else {
      return false;
    }
  }, [state, result, loading]);

  const next = useCallback(() => {
    if (result === null) {
      setState(DocumentApprovalState.APPROVAL);
    } else if (result) {
      setState(DocumentApprovalState.COMPLETE);
    }
  }, [result]);

  useEffect(() => {
    if (!state) {
      next();
    }
  }, [state, next]);

  return {
    prevState,
    state,
    nextDisabled,
    next,
  };
};

interface DocumentApprovalContentProps {
  organization: Organization;
  document: Document;
  onResultProvided: (result: ApprovalResult | null) => void;
  style?: CSSProperties;
}
const DocumentApprovalContent = forwardRef<HTMLInputElement, DocumentApprovalContentProps>(
  ({ organization, document, onResultProvided, style }, ref) => {
    const theme = useTheme();
    const screenSize = useScreenSize();
    const [showDocumentImageDialog, setShowDocumentImageDialog] = useState(false);
    const [result, setResult] = useState<ApprovalResult | null>(null);

    useEffect(() => {
      onResultProvided(result);
    }, [result, onResultProvided]);

    return (
      <OutlineContainerSection
        paddingX={SMALL_HORIZONTAL_SPACING}
        paddingY={SMALL_VERTICAL_SPACING}
        spacing={SMALL_VERTICAL_SPACING}
        direction='row'
        justifyContent='space-between'
        style={{
          ...style,
          flexDirection: screenSize !== ScreenSize.LARGE ? 'column' : 'row',
        }}
        ref={ref}
      >
        <Stack direction='column' spacing={SMALL_VERTICAL_SPACING}>
          <Typography align='center'>Reimburse this expense?</Typography>
          <Stack direction={screenSize === ScreenSize.SMALL ? 'column' : 'row'} spacing={SMALL_HORIZONTAL_SPACING}>
            <Button
              variant={result === ApprovalResult.APPROVED ? 'contained' : 'outlined'}
              color={result === ApprovalResult.APPROVED ? 'secondary' : 'neutral'}
              onClick={() => setResult(ApprovalResult.APPROVED)}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <TickCircle
                  variant={result === ApprovalResult.APPROVED ? 'Bold' : 'Outline'}
                  size='1rem'
                  color={result === ApprovalResult.APPROVED ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                />
                <Typography
                  variant='button'
                  color={result === ApprovalResult.APPROVED ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                >
                  Approve
                </Typography>
              </Stack>
            </Button>

            <Button
              variant={result === ApprovalResult.DENIED ? 'contained' : 'outlined'}
              color={result === ApprovalResult.DENIED ? 'secondary' : 'neutral'}
              onClick={() => setResult(ApprovalResult.DENIED)}
            >
              <Stack direction='row' alignItems='center' spacing={1}>
                <CloseCircle
                  variant={result === ApprovalResult.DENIED ? 'Bold' : 'Outline'}
                  size='1rem'
                  color={result === ApprovalResult.DENIED ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                />
                <Typography
                  variant='button'
                  color={result === ApprovalResult.DENIED ? theme.palette.secondary.contrastText : theme.palette.text.primary}
                >
                  Deny
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>

        <DocumentImg
          document={document}
          containerHeight={128}
          containerWidth={128}
          imgFillContainer
          containerStyle={{
            cursor: 'pointer',
          }}
          onClick={() => setShowDocumentImageDialog(true)}
        />
        <DocumentImageDialog
          organization={organization}
          openFor={showDocumentImageDialog ? document : null}
          onClose={() => setShowDocumentImageDialog(false)}
        />
      </OutlineContainerSection>
    );
  }
);

interface DocumentApprovalProps {
  selected: boolean;
  document: Document & { account: Account | null };
  organization: Organization;
  orgUsersById: { [id: string]: User };
  onSelect: () => void;
  onCompleteChange: (args: { result: ApprovalResult | null }) => void;
  style?: CSSProperties;
}

const DocumentApproval = forwardRef<HTMLDivElement, DocumentApprovalProps>(
  ({ document, orgUsersById, organization, selected, onSelect, onCompleteChange, style }, ref) => {
    const { session } = useSession();
    const outlineContainerBorder = useOutlineContainerBorder();
    const theme = useTheme();
    const { reimbursementApprovalResult } = useDocument();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const isExpense = parseFloat(document.afterTax!) > 0;

    const [result, setResult] = useState<ApprovalResult | null>(null);

    const { prevState, state, nextDisabled, next } = useDocumentApprovalState({
      loading,
      result,
    });

    const sendApprovalResult = useCallback(async () => {
      try {
        setLoading(true);

        await reimbursementApprovalResult({
          documentId: document.id,
          approved: result === ApprovalResult.APPROVED,
        });
      } catch (e) {
        setError('An error has occurred. Please contact support.');
        throw e;
      } finally {
        setLoading(false);
      }
    }, [reimbursementApprovalResult, document, result]);

    useEffect(() => {
      if (prevState !== DocumentApprovalState.COMPLETE && state === DocumentApprovalState.COMPLETE) {
        sendApprovalResult()
          .then(() => {
            onCompleteChange({
              result: result!,
            });
          })
          .catch((e) => {
            throw e;
          });
      } else if (prevState === DocumentApprovalState.COMPLETE && state !== DocumentApprovalState.COMPLETE) {
        onCompleteChange({
          result: null,
        });
      }
    }, [prevState, state, onCompleteChange, sendApprovalResult, result]);

    const onEnter = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !nextDisabled) {
          e.preventDefault();
          next();
        }
      },
      [next, nextDisabled]
    );

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: document.currency });

    const reimburseToUser = orgUsersById[document.reimburseToUserId!];
    const reimbursementUserText = (
      <Typography>
        Submitted by{' '}
        <Tooltip title={`${reimburseToUser.firstName!} ${reimburseToUser.lastName!}`}>
          <Link>{restrictLength(`${reimburseToUser.firstName!} ${reimburseToUser.lastName!}`, 30)}</Link>
        </Tooltip>
      </Typography>
    );

    const content = (
      <Collapse in={state !== DocumentApprovalState.COMPLETE} timeout={300} mountOnEnter unmountOnExit>
        <DelayFade
          in={state === DocumentApprovalState.APPROVAL}
          timeout={300}
          delayMs={state === DocumentApprovalState.APPROVAL ? 300 : undefined}
          mountOnEnter
          unmountOnExit
        >
          <DocumentApprovalContent organization={organization} document={document} onResultProvided={(result) => setResult(result)} />
        </DelayFade>
      </Collapse>
    );

    let background: string;
    if (error) {
      background = alpha(theme.palette.error.main, 0.15);
    } else if (selected) {
      background = alpha(theme.palette.primary.main, 0.05);
    } else {
      background = theme.palette.background.default;
    }

    return (
      <Fade in={true}>
        <OutlineContainer
          ref={ref}
          background={background}
          onClick={onSelect}
          style={{
            position: 'relative',
            ...style,
          }}
          onKeyDown={onEnter}
        >
          <OutlineContainerSection
            borderBottom={outlineContainerBorder}
            paddingX={SMALL_HORIZONTAL_SPACING}
            paddingY={SMALL_VERTICAL_SPACING}
            alignItems='start'
          >
            <Stack spacing={0} alignItems='center'>
              {document.date && (
                <>
                  <Typography variant='small'>{formatInTimeZone(document.date, session?.timeZone || 'UTC', 'MMM').toUpperCase()}</Typography>
                  <Typography>{formatInTimeZone(document.date, session?.timeZone || 'UTC', 'dd')}</Typography>
                </>
              )}
              {!document.date && (
                <>
                  <Typography variant='small'>Unknown</Typography>
                  <Typography variant='small'>Date</Typography>
                </>
              )}
            </Stack>

            <Stack spacing={0} flex={1}>
              <Typography variant='h4'>{document.merchantName}</Typography>

              {reimbursementUserText}
            </Stack>

            <Stack spacing={0}>
              <Typography align='right'>{amountFormatter.format(Math.abs(documentTotal(document)))}</Typography>
              <Chip
                size='small'
                sx={{
                  fontSize: '0.666rem',
                }}
                color={isExpense ? 'error' : 'primary'}
                label={isExpense ? 'EXPENSE' : 'INCOME'}
              />
            </Stack>
          </OutlineContainerSection>

          {content}

          <OutlineContainerSection borderTop={outlineContainerBorder} paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
            <Stack width='100%' direction='row' justifyContent='end' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
              <Button variant='contained' color='primary' disabled={nextDisabled} onClick={next}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='button' color={theme.palette.primary.contrastText}>
                    Next
                  </Typography>
                  <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
                </Stack>
              </Button>
            </Stack>
          </OutlineContainerSection>
        </OutlineContainer>
      </Fade>
    );
  }
);

export function ReimbursementApprovalWidget({ isSuccess }: IWidgetProps) {
  const theme = useTheme();
  const data = useConversationData();
  const { organization, orgUsersById } = useOrganizationData();
  const outlineContainerBorder = useOutlineContainerBorder();
  const { conversationId, addConversationMessage, scrollLockMutable } = useConversation();

  const [selectedDocument, setSelectedDocument] = useState<string | null>(null);
  const [completed, setCompleted] = useState(new Set<string>());

  useEffect(() => {
    if (data) {
      setCompleted(new Set([...data.documentsApproved, ...data.documentsDenied]));
    }
  }, [data]);

  const complete = async ({ id, result }: { id: string; result: ApprovalResult }) => {
    setCompleted((existing) => {
      const newSet = new Set(existing);
      newSet.add(id);
      return newSet;
    });

    await addConversationMessage({
      conversationId: conversationId!,
      includeMessageInConversation: false,
      message: JSON.stringify({
        id,
        approved: result === ApprovalResult.APPROVED,
      }),
    });
  };

  const incomplete = (id: string) => {
    setCompleted((existing) => {
      const newSet = new Set(existing);
      newSet.delete(id);
      return newSet;
    });
  };

  let totalDocuments = 0;
  let documentContent: ReactNode;
  if (!data || !organization || !orgUsersById) {
    documentContent = (
      <TransitionGroup component={null}>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <DocumentPlaceholder />
        </Fade>
      </TransitionGroup>
    );
  } else {
    totalDocuments = data.reimbursementDocuments.length;

    const documentsRequiringApproval = data.reimbursementDocuments
      .filter((d) => !completed.has(d.id))
      .sort((a, b) => {
        const userA = orgUsersById[a.reimburseToUserId!];
        const userB = orgUsersById[b.reimburseToUserId!];

        if (userA.lastName === userB.lastName) {
          return userA.firstName!.localeCompare(userB.firstName!);
        } else {
          return userA.lastName!.localeCompare(userB.lastName!);
        }
      });

    documentContent = (
      <TransitionGroup component={null}>
        {documentsRequiringApproval.map((d, i) => (
          <DelayCollapse
            key={d.id}
            timeout={250}
            delayMs={{ enter: 250 * i, exit: 0 }}
            mountOnEnter
            unmountOnExit
            onEnter={() => (scrollLockMutable.current = false)}
            onEntered={() => (scrollLockMutable.current = true)}
            onExiting={() => (scrollLockMutable.current = false)}
            onExited={() => (scrollLockMutable.current = true)}
          >
            <DocumentApproval
              selected={selectedDocument === d.id}
              document={d}
              organization={organization}
              orgUsersById={orgUsersById}
              onSelect={() => setSelectedDocument(d.id)}
              onCompleteChange={async (completionDetails) => {
                if (completionDetails.result !== null) {
                  await complete({
                    id: d.id,
                    result: completionDetails.result,
                  });
                } else {
                  incomplete(d.id);
                }
              }}
            />
          </DelayCollapse>
        ))}
      </TransitionGroup>
    );
  }

  return (
    <Fade in={true}>
      <OutlineContainer>
        <OutlineContainerSection
          justifyContent='space-between'
          background={theme.palette.background.default}
          borderBottom={completed.size < totalDocuments ? outlineContainerBorder : undefined}
        >
          <DelayFade
            in={!isSuccess && !!totalDocuments && completed.size < totalDocuments}
            delayMs={{ exit: 1000 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Typography variant='h3'>Reimbursements to review</Typography>
          </DelayFade>

          <DelayFade
            in={completed.size === totalDocuments}
            delayMs={{ enter: isSuccess ? 0 : 1300 }}
            timeout={{ enter: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <Stack direction='row'>
              <Typography variant='h3'>Reimbursements reviewed</Typography>

              <CheckCircle style={{ color: theme.palette.primary.main }} />
            </Stack>
          </DelayFade>

          <DelayFade
            in={!isSuccess && !!totalDocuments && completed.size < totalDocuments}
            delayMs={{ exit: 1000 }}
            timeout={{ exit: 300 }}
            mountOnEnter
            unmountOnExit
          >
            <ProgressBar complete={completed.size} total={totalDocuments} />
          </DelayFade>
        </OutlineContainerSection>

        <Collapse in={completed.size < totalDocuments} timeout={300} mountOnEnter unmountOnExit>
          <OutlineContainerSection direction='column' alignItems='stretch' spacing={SMALL_VERTICAL_SPACING}>
            {documentContent}
          </OutlineContainerSection>
        </Collapse>
      </OutlineContainer>
    </Fade>
  );
}
