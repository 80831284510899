import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { CloseCircle } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import { Journal, Organization } from '../../../api';
import { Button, ThreeColumn } from '../../../components';
import { ZonedDatePicker } from '../../../components/zoned-date-picker';
import { getFiscalYear } from '../../../utils/date-utils';

export interface CreateCheckpointDialogProps {
  loading?: boolean;
  organization: Organization;
  journal: Journal;
  open: boolean;
  onClose: () => void;
  onCreate: (balance: number, date: string) => Promise<void>;
}

export function CreateCheckpointDialog({ loading, organization, journal, open, onClose, onCreate }: CreateCheckpointDialogProps) {
  const theme = useTheme();

  const [balance, setBalance] = useState('');
  const [balanceTouched, setBalanceTouched] = useState(false);
  const balanceError = useMemo(() => {
    return !balance || /[A-Za-z]/.test(balance);
  }, [balance]);

  const [date, setDate] = useState<Date>(new Date());
  const [dateTouched, setDateTouched] = useState(false);
  const dateError = useMemo(() => {
    const dateFy = getFiscalYear(date, organization.fyEndMonth, organization.timeZone);

    return dateFy !== journal.fy;
  }, [date, organization, journal]);

  useEffect(() => {
    setBalance('');
    setDate(new Date());
  }, [open]);

  const create = async () => {
    const strippedBalance = balance.replace(/[\s,$]+/g, '');

    await onCreate(parseFloat(strippedBalance), formatInTimeZone(date, organization.timeZone, 'yyyy-MM-dd'));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn align='center'>
          <span></span>
          <span>Create Checkpoint</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>

      <DialogContent>
        <Stack>
          <FormControl required disabled={loading} error={dateTouched && dateError} fullWidth>
            <ZonedDatePicker
              label='Date'
              value={date}
              inputTimeZone={organization.timeZone}
              onChange={(date) => {
                if (date) {
                  setDate(date);
                }
              }}
              onBlur={() => setDateTouched(true)}
            />
            <FormHelperText>{dateTouched && dateError && 'Date outside FY'}</FormHelperText>
          </FormControl>

          <FormControl required disabled={loading} error={balanceTouched && balanceError} fullWidth>
            <TextField
              label='Balance'
              placeholder='Balance...'
              value={balance}
              onBlur={() => setBalanceTouched(true)}
              onChange={(event) => setBalance(event.target.value)}
            />
            <FormHelperText>{balanceTouched && balanceError && 'Invalid balance'}</FormHelperText>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} variant='outlined' color='primary'>
          Close
        </Button>

        <Button disabled={loading || dateError || balanceError} variant='contained' color='primary' onClick={create}>
          {loading ? <CircularProgress style={{ fill: theme.palette.text.primary }} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
