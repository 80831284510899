import { Alert, Button, CircularProgress, Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { Ref, RefObject, createRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import { StatementType, StatementTypeToMimeType, useConversation, useTransaction } from '../../api';
import { MED_HORIZONTAL_SPACING } from '../../theme';
import { BadRequestException } from '../../utils/BadRequestException';
import { ScreenSize, useScreenSize } from '../../utils/use-screen-size';
import { GamifiedProgressBar } from '../gamified-progress-bar';
import { IWidgetProps } from './IWidgetProps';

const uploadAnimation = keyframes`
  from {
    max-height: 1rem;
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    max-height: 0px;
    transform: translateX(100%);
    opacity: 0;
  }
`;

const AnimatedAccount = styled.div`
  &.disappear-exit {
    animation: 300ms ${uploadAnimation} 300ms forwards;
  }
`;

const AccountUploadArea = styled(
  forwardRef(({ children, ...rest }: { children?: React.ReactNode }, ref: Ref<HTMLDivElement>) => {
    return (
      <Stack {...rest} ref={ref}>
        {children}
      </Stack>
    );
  })
)`
  width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
`;

interface AccountUploaderProps {
  account: {
    id: string;
    name: string;
    statementType: string;
    externalId: string;
  };
  loading: boolean;
  onUpload: (file: File) => Promise<void>;
}

const AccountUploader = forwardRef<HTMLDivElement, AccountUploaderProps>(({ account, loading, onUpload, ...rest }, ref) => {
  const theme = useTheme();
  const [fileError, setFileError] = useState<string | null>(null);

  const expectedMimeType = StatementTypeToMimeType[account.statementType as StatementType] as 'application/pdf' | 'text/csv';

  const extensions = {
    'application/pdf': ['.pdf'],
    'text/csv': ['.csv'],
  };

  const accept = {
    [expectedMimeType]: extensions[expectedMimeType],
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept,
    maxSize: 5242880, // 5MB
    multiple: false,
    onDrop: async (acceptedFiles, fileRejections) => {
      setFileError(null);

      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === 'file-too-large') {
            setFileError(`${fileRejection.file.name} is larger than 5 MB.`);
          }
          if (error.code === 'file-invalid-type') {
            setFileError(`File type is not correct for this institution's statements.`);
          }
        }
      }

      for (const file of acceptedFiles) {
        try {
          await onUpload(file);
        } catch (e) {
          if ((e as BadRequestException).response?.data?.type === 'DUPLICATE_STATEMENT') {
            setFileError(`The transactions in this statement already exist in the system.`);
          } else {
            setFileError(`The system was unable to parse ${file.name}.`);
          }
        }
      }
    },
  });

  return (
    <AccountUploadArea {...getRootProps()} {...rest} ref={ref}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography>{account.name}</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <input style={{ display: 'none' }} {...getInputProps()} />
            <Button
              variant='contained'
              color='primary'
              size='small'
              style={{ margin: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                inputRef.current?.click();
              }}
            >
              Upload
            </Button>
          </>
        )}
      </Stack>
      {fileError && (
        <Alert severity='error' style={{ marginTop: theme.spacing(MED_HORIZONTAL_SPACING) }}>
          {fileError}
        </Alert>
      )}
    </AccountUploadArea>
  );
});

export interface StatementUploadListWidgetProps extends IWidgetProps {
  remainingAccounts?: Array<{
    id: string;
    name: string;
    statementType: string;
    externalId: string;
  }>;
  completedAccounts?: string[];
}

export function StatementUploadListWidget({ remainingAccounts }: StatementUploadListWidgetProps) {
  const transitionRefs = useRef<{ [accountId: string]: RefObject<HTMLDivElement> }>({});
  const uploaderRefs = useRef<{ [accountId: string]: { index: number; ref: RefObject<HTMLDivElement> } }>({});
  const { uploadStatement } = useTransaction();
  const { conversationId, addConversationMessage } = useConversation();

  const [accountsLoading, setAccountsLoading] = useState<{ [accountId: string]: boolean }>({});
  const [remainingAccountList, setRemainingAccountList] = useState(remainingAccounts || []);
  const [completedAccountsSet, setCompletedAccountsSet] = useState(new Set());

  useEffect(() => {
    setRemainingAccountList((current) => {
      return current?.filter((a) => !completedAccountsSet.has(a.id));
    });
  }, [completedAccountsSet]);

  const screenSize = useScreenSize();

  const progressBarWidth = useMemo(() => {
    switch (screenSize) {
      case ScreenSize.LARGE:
        return 400;
      case ScreenSize.MEDIUM:
        return 300;
      case ScreenSize.SMALL:
        return 200;
    }
  }, [screenSize]);

  const upload = useCallback(
    async (
      account: {
        id: string;
        name: string;
        statementType: string;
        externalId: string;
      },
      file: File
    ) => {
      if (!conversationId) {
        return;
      }

      try {
        setAccountsLoading((current) => ({
          ...current,
          [account.id]: true,
        }));

        await uploadStatement(file, account.statementType, account.externalId);
      } finally {
        setAccountsLoading((current) => ({
          ...current,
          [account.id]: false,
        }));
      }

      setCompletedAccountsSet((current) => {
        const newCompletedAccountsSet = new Set(current);
        newCompletedAccountsSet.add(account.id);
        return newCompletedAccountsSet;
      });

      // A delay for aesthetic reasons
      await new Promise<void>((resolve) => {
        setTimeout(resolve, 500);
      });

      await addConversationMessage({
        conversationId: conversationId,
        message: JSON.stringify({ [account.id]: true }),
        includeMessageInConversation: false,
      });
    },
    [uploadStatement, conversationId, addConversationMessage]
  );

  return (
    <Stack alignItems='center'>
      <GamifiedProgressBar
        width={progressBarWidth}
        complete={completedAccountsSet.size}
        total={remainingAccountList.length + completedAccountsSet.size}
      />

      <TransitionGroup style={{ width: '100%' }}>
        {remainingAccountList.map((a, i) => {
          if (!transitionRefs.current[a.id]) {
            transitionRefs.current[a.id] = createRef();
          }
          if (!uploaderRefs.current[a.id]) {
            uploaderRefs.current[a.id] = {
              index: i,
              ref: createRef(),
            };
          }

          return (
            <CSSTransition nodeRef={transitionRefs.current[a.id]} key={a.id} timeout={600} classNames='disappear' unmountOnExit>
              <>
                <AnimatedAccount ref={transitionRefs.current[a.id]}>
                  <AccountUploader
                    ref={uploaderRefs.current[a.id].ref}
                    account={a}
                    loading={!!accountsLoading[a.id]}
                    onUpload={(file) => upload(a, file)}
                  />
                </AnimatedAccount>
                {i !== remainingAccountList.length - 1 && <Divider style={{ width: '100%' }} />}
              </>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </Stack>
  );
}
