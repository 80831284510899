import { Public } from '@mui/icons-material';
import { IconButton, MenuItem, MenuList, Popover, Stack, Tooltip } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  DateTimeValidationError,
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { RefObject, useEffect, useRef, useState } from 'react';
import { SMALL_HORIZONTAL_SPACING } from '../theme';
import { getTimezoneAbbreviation } from '../utils/date-utils';

interface TimeZoneDropdownProps {
  anchorEl: RefObject<HTMLElement>;
  disabled?: boolean;
  timeZone: string;
  onBlur: () => void;
  onChange: (timeZone: string) => void;
  open: boolean;
  onClose: () => void;
}
function TimeZoneDropdown({ open, onClose, anchorEl, timeZone, onChange }: TimeZoneDropdownProps) {
  const [searchQuery, setSearchQuery] = useState({
    q: '',
    lastChange: 0,
  });

  useEffect(() => {
    setSearchQuery({
      q: '',
      lastChange: 0,
    });
  }, [open]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!/^[a-zA-Z/]$/.test(event.key)) {
      return;
    }

    const ONE_SECOND = 1000;

    setSearchQuery((previous) => {
      const now = new Date();

      if (now.getTime() - previous.lastChange > ONE_SECOND) {
        return {
          q: event.key,
          lastChange: now.getTime(),
        };
      } else {
        return {
          q: previous.q + event.key,
          lastChange: now.getTime(),
        };
      }
    });
  };

  const filteredTimeZones = Intl.supportedValuesOf('timeZone').filter((tz) => {
    return !searchQuery.q || tz === timeZone || tz.toLowerCase().includes(searchQuery.q.toLowerCase());
  });

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      onKeyDown={handleKeyDown}
    >
      <MenuList>
        {filteredTimeZones.map((i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onChange(i);
              onClose();
            }}
            selected={timeZone === i}
          >
            {i}
          </MenuItem>
        ))}
      </MenuList>
    </Popover>
  );
}

export interface ZonedDatePickerProps extends DatePickerProps<Date> {
  inputTimeZone: string;
  onBlur?: () => void;
  onTimeZoneChange?: (timeZone: string) => void;
}

export function ZonedDatePicker({ label, value, inputTimeZone, onChange, onBlur, onTimeZoneChange, ...props }: ZonedDatePickerProps) {
  const [tz, setTz] = useState(inputTimeZone || 'Etc/UTC');
  const [timeZonePickerOpen, setTimeZonePickerOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setTz(inputTimeZone || 'Etc/UTC');
  }, [inputTimeZone]);

  const handleDateChange = (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!newValue && onChange) {
      onChange(newValue, context);
    }

    if (onChange) {
      const unzoned = newValue ? fromZonedTime(newValue, tz) : null;
      onChange(unzoned, context);
    }
  };

  const zoned = value && tz ? toZonedTime(value, tz) : null;

  const tzLabel = label && typeof label === 'string' ? `${label} (${getTimezoneAbbreviation(tz)})` : label;

  return (
    <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING} alignItems='center'>
      <DatePicker label={tzLabel} format='yyyy-MM-dd' value={zoned} onChange={handleDateChange} sx={{ flex: 1 }} {...props} />
      <Tooltip title='Change time zone'>
        <IconButton ref={anchorEl} onClick={() => setTimeZonePickerOpen(true)} disabled={!onTimeZoneChange}>
          <Public />
        </IconButton>
      </Tooltip>
      <TimeZoneDropdown
        anchorEl={anchorEl}
        open={timeZonePickerOpen}
        onClose={() => setTimeZonePickerOpen(false)}
        timeZone={tz}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        onChange={(tz) => {
          setTz(tz);

          if (onTimeZoneChange) {
            onTimeZoneChange(tz);
          }
        }}
      />
    </Stack>
  );
}

export interface ZonedDateTimePickerProps extends DateTimePickerProps<Date> {
  inputTimeZone: string;
  onBlur: () => void;
  onTimeZoneChange?: (timeZone: string) => void;
}

export function ZonedDateTimePicker({ label, value, inputTimeZone, onChange, onBlur, onTimeZoneChange, ...props }: ZonedDateTimePickerProps) {
  const [tz, setTz] = useState(inputTimeZone || 'Etc/UTC');
  const [timeZonePickerOpen, setTimeZonePickerOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setTz(inputTimeZone || 'Etc/UTC');
  }, [inputTimeZone]);

  const handleDateChange = (newValue: Date | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
    if (!newValue && onChange) {
      onChange(newValue, context);
    }

    if (onChange) {
      const unzoned = newValue ? fromZonedTime(newValue, tz) : null;
      onChange(unzoned, context);
    }
  };

  const zoned = value && tz ? toZonedTime(value, tz) : null;

  const tzLabel = label && typeof label === 'string' ? `${label} (${getTimezoneAbbreviation(tz)})` : label;

  return (
    <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING} alignItems='center'>
      <DateTimePicker label={tzLabel} format='yyyy-MM-dd' value={zoned} onChange={handleDateChange} sx={{ flex: 1 }} {...props} />
      <Tooltip title='Change time zone'>
        <IconButton onClick={() => setTimeZonePickerOpen(true)} disabled={!onTimeZoneChange}>
          <Public />
        </IconButton>
      </Tooltip>
      <TimeZoneDropdown
        anchorEl={anchorEl}
        open={timeZonePickerOpen}
        onClose={() => setTimeZonePickerOpen(false)}
        timeZone={tz}
        onBlur={onBlur}
        onChange={(tz) => {
          setTz(tz);

          if (onTimeZoneChange) {
            onTimeZoneChange(tz);
          }
        }}
      />
    </Stack>
  );
}
