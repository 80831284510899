import { CircularProgress, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ArrowSwapHorizontal, Copy, CopySuccess, DollarSquare, TrendDown } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Asset, JournalEntry, Organization, useAdmin } from '../../../api';
import { AdminOrganizationSelect, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';

const useAdminData = (selectedOrg: Organization | null) => {
  const { fetchOrganizations, fetchAssets, fetchJournals, fetchJournalEntries, organizations, assets, journals, journalEntries } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    fetchAssets(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchJournals(selectedOrg.id!).catch((e) => {
      throw e;
    });
  }, [fetchAssets, selectedOrg]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    const entryPromises = [];

    for (const journal of journals[selectedOrg.id!]) {
      entryPromises.push(fetchJournalEntries(journal.id));
    }

    Promise.all(entryPromises).catch((e) => {
      throw e;
    });
  }, [journals]);

  const orgAssets = useMemo(() => {
    if (!selectedOrg || !assets[selectedOrg.id!]) {
      return null;
    }

    return assets[selectedOrg.id!];
  }, [assets, selectedOrg]);

  const assetJournalEntries = useMemo(() => {
    if (!selectedOrg || !journals[selectedOrg.id!]) {
      return null;
    }

    const depreciationEntries = [];
    const saleEntries = [];

    for (const journal of journals[selectedOrg.id!]) {
      for (const je of journalEntries[journal.id] || []) {
        if (je.tags.find((t) => t.startsWith('asset_depreciation::'))) {
          depreciationEntries.push(je);
          continue;
        }

        if (je.tags.find((t) => t.startsWith('asset_sale::'))) {
          saleEntries.push(je);
        }
      }
    }

    return {
      depreciationEntries,
      saleEntries,
    };
  }, [journals, journalEntries, selectedOrg]);

  return {
    organizations,
    assets: orgAssets,
    assetJournalEntries,
  };
};

function AssetsList({
  assets,
  depreciationJournalEntries,
  saleJournalEntries,
  style,
}: {
  assets: Asset[] | null;
  depreciationJournalEntries: JournalEntry[] | null;
  saleJournalEntries: JournalEntry[] | null;
  style?: CSSProperties;
}) {
  const theme = useTheme();

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (assetId: string) => {
    setIdCopied(assetId);
    await navigator.clipboard.writeText(assetId);
  }, []);

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const asset = params.row as Asset;
        const loading = false;

        return (
          <Tooltip title={loading ? 'Loading' : asset.id}>
            <span>
              {loading ? (
                <CircularProgress size='1rem' />
              ) : (
                <IconButton onClick={() => copy(asset.id)}>
                  {idCopied === asset.id ? (
                    <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  ) : (
                    <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                  )}
                </IconButton>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      display: 'flex',
      field: 'type',
      headerName: 'Type',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'purchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'purchasePrice',
      headerName: 'Purchase Price',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'annualAmortizationRate',
      headerName: 'Annual Amortization Rate',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'value',
      headerName: 'Value',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'soldQuantity',
      headerName: 'Quantity Sold',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'actions',
      headerName: 'Actions',
      width: 128,
      renderCell: (params) => {
        const asset = params.row as Asset;

        const assetDepreciationJournalEntries = depreciationJournalEntries!
          .filter((je) => je.tags.find((t) => t === `asset_depreciation::${asset.purchaseTransactionId}`))
          .map((e) => `journalEntryIds=${e.id}`);

        const saleTags = new Set(asset.sales.map((s) => `asset_sale::${s.transactionId}`));
        const assetSaleJournalEntries = saleJournalEntries!
          .filter((je) => je.tags.find((t) => saleTags.has(t)))
          .map((e) => `journalEntryIds=${e.id}`);

        return (
          <Stack spacing={0} direction='row'>
            <Tooltip title='View Transaction'>
              <span>
                <IconButton
                  onClick={() => {
                    window.open(`/admin/transactions?tab=ALL&transactionIds=${asset.purchaseTransactionId}`, '_blank');
                  }}
                >
                  <ArrowSwapHorizontal size='1rem' variant='Bold' color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Depreciation Journal Entries'>
              <span>
                <IconButton
                  disabled={!assetDepreciationJournalEntries.length}
                  onClick={() => {
                    const journalEntryIdsParam = assetDepreciationJournalEntries.join('&');
                    window.open(`/admin/journals?${journalEntryIdsParam}`, '_blank');
                  }}
                >
                  <TrendDown
                    size='1rem'
                    variant='Bold'
                    color={assetDepreciationJournalEntries.length ? theme.palette.primary.main : theme.palette.text.disabled}
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Sale Journal Entries'>
              <span>
                <IconButton
                  disabled={!assetSaleJournalEntries.length}
                  onClick={() => {
                    const journalEntryIdsParam = assetSaleJournalEntries.join('&');
                    window.open(`/admin/journals?${journalEntryIdsParam}`, '_blank');
                  }}
                >
                  <DollarSquare
                    size='1rem'
                    variant='Bold'
                    color={assetSaleJournalEntries.length ? theme.palette.primary.main : theme.palette.text.disabled}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack style={style}>
      <DataGrid
        loading={!assets}
        rows={assets || []}
        columns={columns}
        pageSizeOptions={[50]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'purchaseDate', sort: 'desc' }],
          },
        }}
      />
    </Stack>
  );
}

export function AdminAssetsPage({ ...props }) {
  const theme = useTheme();

  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg } = useSelectedOrganization(null);

  const { assets, organizations, assetJournalEntries } = useAdminData(selectedOrg);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Calculations' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Assets' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
              }}
            />
          </Stack>

          <AssetsList
            assets={assets}
            depreciationJournalEntries={assetJournalEntries ? assetJournalEntries.depreciationEntries : null}
            saleJournalEntries={assetJournalEntries ? assetJournalEntries.saleEntries : null}
            style={{ flex: 1 }}
          />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
