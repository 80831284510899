import { CircularProgress, IconButton, Link, Stack, Tooltip, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Add, UserRemove, UserTick } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Role, User, useOrganization, useSession } from '../../api';
import { Button, ConfirmDialog, InviteUserDialog, PageBody, PageContainer, PageHeader, Search } from '../../components';
import { useOneClickDataGridEditing } from '../../utils/datagrid';

const useTeam = () => {
  const { session } = useSession();
  const {
    fetchUsers,
    enableUser: enableUserApi,
    disableUser: disableUserApi,
    inviteUser: inviteUserApi,
    resendInvite: resendInviteApi,
    updateUser: updateUserApi,
    users,
  } = useOrganization();

  useEffect(() => {
    if (!session) {
      return;
    }

    fetchUsers().catch((e) => {
      throw e;
    });
  }, [session, fetchUsers]);

  const disableUser = useCallback(
    async (userId: string) => {
      await disableUserApi(userId);
      await fetchUsers();
    },
    [disableUserApi, fetchUsers]
  );

  const enableUser = useCallback(
    async (userId: string) => {
      await enableUserApi(userId);
      await fetchUsers();
    },
    [enableUserApi, fetchUsers]
  );

  const inviteUser = useCallback(
    async (email: string, role: Role) => {
      await inviteUserApi(email, role);
      await fetchUsers();
    },
    [inviteUserApi, fetchUsers]
  );

  const updateUser = useCallback(
    async (id: string, changes: Partial<User>) => {
      await updateUserApi(id, changes);
      await fetchUsers();
    },
    [updateUserApi, fetchUsers]
  );

  const resendUserInvite = useCallback(
    async (userId: string) => {
      await resendInviteApi(userId);
      await fetchUsers();
    },
    [resendInviteApi, fetchUsers]
  );

  return {
    users,
    enableUser,
    disableUser,
    inviteUser,
    resendUserInvite,
    updateUser,
  };
};

function ConfirmEnableDisableDialog({
  confirmEnableDisableFor,
  setConfirmEnableDisableFor,
  setLoadingUsers,
  enableUser,
  disableUser,
  mode,
}: {
  confirmEnableDisableFor: null | User;
  setConfirmEnableDisableFor: (user: User | null) => void;
  setLoadingUsers: (fn: (existing: Set<string>) => Set<string>) => void;
  enableUser: (id: string) => Promise<void>;
  disableUser: (id: string) => Promise<void>;
  mode: 'enable' | 'disable' | null;
}) {
  return (
    <ConfirmDialog
      open={!!confirmEnableDisableFor}
      onClose={() => setConfirmEnableDisableFor(null)}
      onConfirm={async () => {
        setConfirmEnableDisableFor(null);

        try {
          setLoadingUsers((current) => {
            const newSet = new Set(current);
            newSet.add(confirmEnableDisableFor!.id!);
            return newSet;
          });

          if (mode === 'enable') {
            await enableUser(confirmEnableDisableFor!.id!);
          } else {
            await disableUser(confirmEnableDisableFor!.id!);
          }
        } finally {
          setLoadingUsers((current) => {
            const newSet = new Set(current);
            newSet.delete(confirmEnableDisableFor!.id!);
            return newSet;
          });
        }
      }}
      message={
        confirmEnableDisableFor
          ? `Are you sure you want to ${mode === 'enable' ? 'enable' : 'disable'} ${confirmEnableDisableFor.firstName!} ${confirmEnableDisableFor.lastName!} (${confirmEnableDisableFor.email!})?`
          : ''
      }
    />
  );
}

function getRoleLabel(role: Role) {
  switch (role) {
    case Role.OWNER:
      return 'Owner';
    case Role.CONTRACTOR:
      return 'Contractor';
    case Role.EMPLOYEE:
      return 'Employee';
    case Role.ADMIN:
      return 'Admin';
    default:
      throw new Error();
  }
}

function getStatusLabel(user: User) {
  if (user.isDisabled) {
    return 'Disabled';
  }

  if (!user.isRegistered) {
    return 'Invite Sent';
  }

  return 'Active';
}

export function TeamPage({ ...props }) {
  const theme = useTheme();
  const { users, enableUser, disableUser, inviteUser, resendUserInvite, updateUser } = useTeam();
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [confirmEnableDisableFor, setConfirmEnableDisableFor] = useState<null | User>(null);
  const [confirmEnableDisableMode, setConfirmEnableDisableMode] = useState<'enable' | 'disable' | null>(null);
  const [confirmResendInviteFor, setConfirmResendInviteFor] = useState<null | User>(null);
  const [confirmRoleUpdate, setConfirmRoleUpdate] = useState<null | User>(null);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(new Set<string>());

  const { handleCellClick, cellModesModel, handleCellModesModelChange } = useOneClickDataGridEditing();

  const filteredUsers = useMemo(() => {
    if (!searchCriteria.length || !users) {
      return users;
    }

    return users.filter((user) => {
      for (const searchCriterion of searchCriteria) {
        if (user.firstName && user.firstName.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        if (user.lastName && user.lastName.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const userRoleLabel = getRoleLabel(user.role);
        if (userRoleLabel.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const userStatusLabel = getStatusLabel(user);
        if (userStatusLabel.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        return false;
      }
    });
  }, [searchCriteria, users]);

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'loading',
      headerName: '',
      width: 32,
      align: 'center',
      renderCell: (params) => {
        const user = params.row as User;

        if (loadingUsers.has(user.id!)) {
          return <CircularProgress size='1rem' />;
        } else {
          return null;
        }
      },
    },
    {
      display: 'flex',
      field: 'firstName',
      headerName: 'First Name',
      valueGetter: (_value, row) => {
        const user = row as User;

        return user.firstName || 'Unknown';
      },
    },
    {
      display: 'flex',
      field: 'lastName',
      headerName: 'Last Name',
      valueGetter: (_value, row) => {
        const user = row as User;

        return user.lastName || 'Unknown';
      },
    },
    {
      display: 'flex',
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      display: 'flex',
      field: 'role',
      headerName: 'Role',
      // editable: true,
      width: 128,
      renderCell: (params) => {
        const user = params.row as User;
        return user.role;
      },
      // valueGetter: (params) => {
      //   const role = params.value as Role;
      //   return {
      //     id: role,
      //     name: getRoleLabel(role),
      //   };
      // },
      // valueSetter: (params) => {
      //   const role = params.value as {
      //     id: Role;
      //     name: string;
      //   };
      //   return { ...params.row, role: role.id } as User;
      // },
      // renderCell: (params) => {
      //   const user = params.row as User;

      //   return (
      //     <DataGridSelectDataGridCellView
      //       disabled={user.role === Role.OWNER || loadingUsers.has(user.id!)}
      //       params={params}
      //       items={Object.values(Role).map((r) => ({
      //         id: r,
      //         name: getRoleLabel(r),
      //       }))}
      //     />
      //   );
      // },
      // renderEditCell: (params) => {
      //   const roleData = params.value as {
      //     id: Role;
      //     name: string;
      //   };

      //   let values = [Role.CONTRACTOR, Role.EMPLOYEE];
      //   if (roleData.id !== Role.CONTRACTOR && roleData.id !== Role.EMPLOYEE) {
      //     values = [roleData.id, ...values];
      //   }
      //   return (
      //     <DataGridSelectDataGridCellEdit
      //       disabledItems={new Set([Role.ADMIN, Role.OWNER])}
      //       params={params}
      //       items={values.map((r) => ({
      //         id: r,
      //         name: getRoleLabel(r),
      //       }))}
      //     />
      //   );
      // },
    },
    {
      display: 'flex',
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const user = params.row as User;

        const statusLabel = getStatusLabel(user);

        if (statusLabel === 'Invite Sent' && !user.isDisabled) {
          return (
            <>
              <span style={{ marginRight: theme.spacing(2) }}>{statusLabel}</span>
              <span>(</span>
              <Link
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => setConfirmResendInviteFor(user)}
              >
                Resend
              </Link>
              <span>)</span>
            </>
          );
        } else {
          return statusLabel;
        }
      },
    },
    {
      display: 'flex',
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const user = params.row as User;

        let disableDisabledReason: string | null = null;
        if (user.role === Role.OWNER) {
          disableDisabledReason = 'cannot disable owner';
        }

        return (
          <Stack direction='row' spacing={0}>
            {user.isDisabled ? (
              <Tooltip title='Enable user'>
                <span>
                  <IconButton
                    disabled={!user.isDisabled}
                    onClick={() => {
                      setConfirmEnableDisableFor(user);
                      setConfirmEnableDisableMode('enable');
                    }}
                  >
                    <UserTick variant='Bold' color={!user.isDisabled ? theme.palette.text.disabled : theme.palette.primary.main} size='1.1rem' />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={disableDisabledReason ? `Disable user - ${disableDisabledReason}` : 'Disable user'}>
                <span>
                  <IconButton
                    disabled={user.role === Role.OWNER}
                    onClick={() => {
                      setConfirmEnableDisableFor(user);
                      setConfirmEnableDisableMode('disable');
                    }}
                  >
                    <UserRemove
                      variant='Bold'
                      color={user.role === Role.OWNER ? theme.palette.text.disabled : theme.palette.primary.main}
                      size='1.1rem'
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  const changeRole = async (id: string, newUser: User) => {
    try {
      setLoadingUsers((current) => {
        const newSet = new Set(current);
        newSet.add(id);
        return newSet;
      });

      await updateUser(id, {
        role: newUser.role,
      });
    } finally {
      setLoadingUsers((current) => {
        const newSet = new Set(current);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  return (
    <PageContainer {...props}>
      <PageHeader title='Team Members' />
      <PageBody gutter='thin'>
        <Stack sx={{ flex: 1 }}>
          <Stack direction='row' justifyContent='end'>
            <Search style={{ flex: 1 }} searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} />

            <Button variant='contained' color='primary' onClick={() => setInviteUserDialogOpen(true)}>
              <Add size='1.1rem' style={{ marginRight: theme.spacing(2) }} />
              <span>Invite User</span>
            </Button>
          </Stack>

          <DataGrid
            style={{ flex: 1 }}
            loading={users === null}
            rows={filteredUsers || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
              sorting: {
                sortModel: [{ field: 'role', sort: 'asc' }],
              },
            }}
            onCellClick={handleCellClick}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            isCellEditable={(params) => {
              const user = params.row as User;

              return !!params.colDef.editable && user.role !== Role.ADMIN && user.role !== Role.OWNER && !loadingUsers.has(user.id!);
            }}
            pageSizeOptions={[50]}
            processRowUpdate={(newRow, oldRow) => {
              const old = oldRow as User;
              const updated = newRow as User;

              if (updated.role !== old.role) {
                setConfirmRoleUpdate(updated);
                return old;
              }

              return updated;
            }}
          />

          <ConfirmEnableDisableDialog
            confirmEnableDisableFor={confirmEnableDisableFor}
            setConfirmEnableDisableFor={setConfirmEnableDisableFor}
            setLoadingUsers={setLoadingUsers}
            disableUser={disableUser}
            enableUser={enableUser}
            mode={confirmEnableDisableMode}
          />

          <InviteUserDialog
            open={inviteUserDialogOpen}
            onClose={() => setInviteUserDialogOpen(false)}
            onInvite={async (email, role) => {
              await inviteUser(email, role);
              setInviteUserDialogOpen(false);
            }}
          />

          <ConfirmDialog
            open={!!confirmResendInviteFor}
            onClose={() => setConfirmResendInviteFor(null)}
            message={confirmResendInviteFor ? `Are you sure you want to resend an invite to ${confirmResendInviteFor.email!}?` : ''}
            onConfirm={async () => {
              try {
                setLoadingUsers((current) => {
                  const newSet = new Set(current);
                  newSet.add(confirmResendInviteFor!.id!);
                  return newSet;
                });

                await resendUserInvite(confirmResendInviteFor!.id!);
                setConfirmResendInviteFor(null);
              } finally {
                setLoadingUsers((current) => {
                  const newSet = new Set(current);
                  newSet.delete(confirmResendInviteFor!.id!);
                  return newSet;
                });
              }
            }}
          />

          <ConfirmDialog
            open={!!confirmRoleUpdate}
            onClose={() => setConfirmRoleUpdate(null)}
            message={`Are you sure you want to update this user's role to "${confirmRoleUpdate ? getRoleLabel(confirmRoleUpdate.role) : ''}"`}
            onConfirm={async () => {
              await changeRole(confirmRoleUpdate!.id!, confirmRoleUpdate!);
              setConfirmRoleUpdate(null);
            }}
          />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
