import { TipsAndUpdates } from '@mui/icons-material';
import {
  alpha,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Select,
  Stack,
  SwipeableDrawer,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  AddCircle,
  ArrowLeft2,
  Book,
  CloseCircle,
  DocumentCopy,
  DocumentText,
  Edit2,
  Eye,
  InfoCircle,
  MoreCircle,
  TableDocument,
  TickCircle,
} from 'iconsax-react';
import React, { HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Account,
  AccountReconciliationSuggestion,
  AccountReconciliationSuggestionType,
  Checkpoint,
  CreateJournalEntryArgs,
  JournalEntry,
  JournalEntryLineType,
  Organization,
  StandardAccounts,
  TopLevelAccountType,
  Transaction,
} from '../../../api';
import { Button, ConfirmDialog, ThreeColumn } from '../../../components';
import { SimpleTable } from '../../../components/simple-table';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { entryIsCurrent } from '../../../utils/journal-entry-util';
import { JournalEntryDialog } from '../journal/journal-entry-dialog';
import { ImportTransactionsDialog } from '../transactions/import-transactions';
import { AccountCoverage } from './account-coverage';
import { CreateCheckpointDialog } from './create-checkpoint-dialog';
import { ReconciliationContext } from './reconciliation-context';
import { isExcludedTx, isToggledOffTx } from './util';

function AccountSelect({ accounts, selected, onSelect }: { accounts: Account[]; selected: Account; onSelect: (account: Account) => void }) {
  const theme = useTheme();

  const onChangeAccount = (accountId: string) => {
    const account = accounts.find((a) => a.id === accountId);
    onSelect(account!);
  };

  return (
    <FormControl size='small'>
      <InputLabel id='account-select-label'>Account</InputLabel>
      <Select
        label='Account'
        labelId='account-select-label'
        autoWidth
        value={selected?.id || ''}
        onChange={(event) => onChangeAccount(event.target.value)}
        style={{
          minWidth: theme.spacing(36),
        }}
      >
        {accounts
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((o) => (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

function CheckpointSelect({
  organization,
  checkpoints,
  selected,
  onSelect,
}: {
  organization: Organization;
  checkpoints: Checkpoint[];
  selected: Checkpoint | null;
  onSelect: (account: Checkpoint | null) => void;
}) {
  const theme = useTheme();

  const onChangeCheckpoint = (checkpointId: string) => {
    if (checkpointId === '') {
      onSelect(null);
    } else {
      const checkpoint = checkpoints.find((a) => a.id === checkpointId);
      onSelect(checkpoint!);
    }
  };

  return (
    <FormControl size='small'>
      <InputLabel id='checkpoint-select-label'>Checkpoint</InputLabel>
      <Select
        label='Checkpoint'
        labelId='checkpoint-select-label'
        autoWidth
        value={selected?.id || ''}
        onChange={(event) => onChangeCheckpoint(event.target.value)}
        style={{
          minWidth: theme.spacing(24),
        }}
      >
        <MenuItem value={''}>None</MenuItem>
        {checkpoints
          .sort((a, b) => a.date.getTime() - b.date.getTime())
          .map((o) => (
            <MenuItem key={o.id} value={o.id}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                <span>{formatInTimeZone(o.date, organization.timeZone, 'MMM d')}</span>
                {o.reconciled ? (
                  <TickCircle size={16} variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <CloseCircle size={16} variant='Bold' color={theme.palette.error.main} />
                )}
              </Stack>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

interface MetricCardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  primaryContent: React.ReactNode;
  secondaryContent?: React.ReactNode;
}

function MetricCard({ title, primaryContent, secondaryContent, ...props }: MetricCardProps) {
  const theme = useTheme();

  return (
    <Stack
      border={`1px solid ${theme.palette.border.main}`}
      borderRadius={theme.roundedCorners(5)}
      paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
      paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
      spacing={theme.spacing(SMALL_VERTICAL_SPACING)}
      {...props}
    >
      <Typography variant='h5'>{title}</Typography>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {primaryContent}
        {secondaryContent}
      </Stack>
    </Stack>
  );
}

function TransactionsTable({ ...props }) {
  const theme = useTheme();
  const {
    account,
    accounts,
    organization,
    toggledTransactions,
    setTransactionToggle,
    checkpointTransactions,
    checkpointTransactionTotal,
    selectedTransactions,
    updateSelectedTransactions,
  } = useContext(ReconciliationContext);

  const [showImportDialog, setShowImportDialog] = useState(false);

  const accountAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'toggle',
      headerName: '',
      width: 32,
      align: 'center',
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const isToggledOff = isToggledOffTx(toggledTransactions, transaction);

        if (!transaction.approved) {
          return (
            <Tooltip title='View Unreviewed'>
              <span>
                <IconButton onClick={() => window.open('/admin/transactions?tab=UNREVIEWED', '_blank')}>
                  <Eye variant='Bold' size='1rem' color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={isToggledOff ? 'Unexclude' : 'Exclude'}>
              <Switch size='small' checked={!isToggledOff} onChange={(event) => setTransactionToggle(transaction.id, event.target.checked)} />
            </Tooltip>
          );
        }
      },
    },
    {
      display: 'flex',
      field: 'description',
      headerName: 'Transaction',
      flex: 1,
      sortComparator: (_v1, _v2, param1, param2) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const a = param1.api.getRow(param1.id) as Transaction;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const b = param2.api.getRow(param2.id) as Transaction;

        return (a.postedDate || a.date).getTime() - (b.postedDate || b.date).getTime();
      },
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const isExcluded = isExcludedTx(toggledTransactions, transaction);

        return (
          <Box>
            <Typography
              color={isExcluded ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: isExcluded ? 'italic' : undefined,
              }}
            >
              {transaction.name}
            </Typography>
            <Typography
              variant='small'
              color={isExcluded ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: isExcluded ? 'italic' : undefined,
              }}
            >
              {organization && formatInTimeZone(transaction.postedDate || transaction.date, organization.timeZone, 'MMM d')}
            </Typography>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'amount',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const txAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode || 'CAD' });
        const isExcluded = isExcludedTx(toggledTransactions, transaction);

        return (
          <Typography
            color={isExcluded ? theme.palette.text.disabled : undefined}
            style={{
              fontStyle: isExcluded ? 'italic' : undefined,
            }}
          >
            {txAmountFormatter.format(-1 * parseFloat(transaction.amount))}
          </Typography>
        );
      },
    },
  ];

  let numExcluded = 0;
  let numIncluded = 0;
  for (const change of Object.values(toggledTransactions)) {
    if (change) {
      numIncluded++;
    } else {
      numExcluded++;
    }
  }

  let txText = `${(checkpointTransactions || []).length} items`;
  if (numExcluded && numIncluded) {
    txText += ` (${numIncluded} included, ${numExcluded} excluded)`;
  } else if (numExcluded) {
    txText += ` (${numExcluded} excluded)`;
  } else if (numIncluded) {
    txText += ` (${numIncluded} included)`;
  }

  return (
    <Stack spacing={SMALL_VERTICAL_SPACING} {...props}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Transactions</Typography>
        {organization && accounts && account && (
          <>
            <IconButton size='small' onClick={() => setShowImportDialog(true)}>
              <AddCircle color={theme.palette.primary.main} size='1rem' />
            </IconButton>
            <ImportTransactionsDialog
              open={showImportDialog}
              onClose={() => setShowImportDialog(false)}
              organization={organization}
              accounts={accounts}
              preselectAccount={account}
            />
          </>
        )}
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography>{txText}</Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{selectedTransactions.length ? 'Selected Total' : 'Total'}</Typography>
          <Typography variant='h5'>
            {checkpointTransactionTotal !== null ? accountAmountFormatter.format(checkpointTransactionTotal) : 'N/A'}
          </Typography>
        </Stack>
      </Stack>

      <DataGridPro
        loading={!checkpointTransactions}
        rows={checkpointTransactions || []}
        columns={columns}
        pageSizeOptions={[100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'description', sort: 'desc' }],
          },
        }}
        rowSelectionModel={selectedTransactions}
        onRowSelectionModelChange={(rows) => updateSelectedTransactions(rows)}
        style={{ flex: 1 }}
      />
    </Stack>
  );
}

function JournalEntriesTable({ ...props }) {
  const theme = useTheme();
  const {
    account,
    accounts,
    journal,
    toggledOffJournalEntries,
    setJournalEntryToggle,
    checkpointEntries,
    impliedEntries,
    selectedJournalEntries,
    selectedEntriesTotal,
    updateSelectedJournalEntries,
    createJournalEntry,
  } = useContext(ReconciliationContext);

  const [journalEntryLoading, setJournalEntryLoading] = useState(false);
  const [createJournalEntryDialogOpen, setCreateJournalEntryDialogOpen] = useState(false);

  const accountsById = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [id: string]: Account }
    );
  }, [accounts]);

  const createEntry = useCallback(
    async (entry: CreateJournalEntryArgs) => {
      try {
        setJournalEntryLoading(true);

        await createJournalEntry(entry);

        setCreateJournalEntryDialogOpen(false);
      } finally {
        setJournalEntryLoading(false);
      }
    },
    [createJournalEntry]
  );

  const accountAmountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'toggle',
      headerName: '',
      width: 32,
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry & { implied?: boolean };
        const txId = journalEntry.implied ? journalEntry.tags.find((t) => t.startsWith('transaction::'))?.split('::')[1] : null;

        return journalEntry.implied ? (
          <Tooltip title={`Implied by unignored transaction ${txId!}`}>
            <MoreCircle size='1.5rem' color={theme.palette.neutral.main} />
          </Tooltip>
        ) : (
          <Switch
            size='small'
            checked={!toggledOffJournalEntries.has(journalEntry.id)}
            onChange={(event) => setJournalEntryToggle(journalEntry.id, event.target.checked)}
          />
        );
      },
    },
    {
      display: 'flex',
      field: 'description',
      headerName: 'Journal Entry',
      flex: 1,
      sortComparator: (_v1, _v2, param1, param2) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const a = param1.api.getRow(param1.id) as JournalEntry;
        const aDate = parse(a.reconciliationDate || a.date, 'yyyy-MM-dd', new Date());

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const b = param2.api.getRow(param2.id) as JournalEntry;
        const bDate = parse(b.reconciliationDate || b.date, 'yyyy-MM-dd', new Date());

        return aDate.getTime() - bDate.getTime();
      },
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry;

        return (
          <Box>
            <Typography
              color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
              }}
            >
              {journalEntry.memo}
            </Typography>
            <Typography
              variant='small'
              color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
              style={{
                fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
              }}
            >
              {format(parse(journalEntry.reconciliationDate || journalEntry.date, 'yyyy-MM-dd', new Date()), 'MMM d')}
            </Typography>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'amount',
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (params) => {
        const journalEntry = params.row as JournalEntry;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

        const debitTypes = new Set([TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE]);

        const amount = [...journalEntry.credits, ...journalEntry.debits].reduce((sum, current) => {
          if (current.accountId !== account?.id) {
            return sum;
          }

          if (
            (debitTypes.has(account.topLevelType) && current.type === JournalEntryLineType.DEBIT) ||
            (!debitTypes.has(account.topLevelType) && current.type === JournalEntryLineType.CREDIT)
          ) {
            return sum + parseFloat(current.amount);
          } else {
            return sum - parseFloat(current.amount);
          }
        }, 0);

        return (
          <Typography
            color={toggledOffJournalEntries.has(journalEntry.id) ? theme.palette.text.disabled : undefined}
            style={{
              fontStyle: toggledOffJournalEntries.has(journalEntry.id) ? 'italic' : undefined,
            }}
          >
            {amountFormatter.format(amount)}
          </Typography>
        );
      },
    },
  ];

  return (
    <Stack spacing={SMALL_VERTICAL_SPACING} {...props}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>Journal Entries</Typography>

        {journal && accountsById && (
          <>
            <IconButton size='small' onClick={() => setCreateJournalEntryDialogOpen(true)}>
              <AddCircle color={theme.palette.primary.main} size='1rem' />
            </IconButton>

            <JournalEntryDialog
              loading={journalEntryLoading}
              open={createJournalEntryDialogOpen}
              onClose={() => setCreateJournalEntryDialogOpen(false)}
              journal={journal}
              journalAccountsById={accountsById}
              onCreate={createEntry}
            />
          </>
        )}
      </Stack>

      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography>
          {checkpointEntries &&
            `${checkpointEntries.length} items` + (toggledOffJournalEntries.size > 0 ? ` (${toggledOffJournalEntries.size} excluded)` : '')}
        </Typography>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{selectedJournalEntries.length ? 'Selected Total' : 'Total'}</Typography>
          <Typography variant='h5'>{selectedEntriesTotal !== null ? accountAmountFormatter.format(selectedEntriesTotal) : 'N/A'}</Typography>
        </Stack>
      </Stack>

      <DataGridPro
        loading={!checkpointEntries || !impliedEntries}
        rows={[...(checkpointEntries || []), ...impliedEntries]}
        columns={columns}
        pageSizeOptions={[100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'description', sort: 'desc' }],
          },
        }}
        rowSelectionModel={selectedJournalEntries}
        onRowSelectionModelChange={(rows) => updateSelectedJournalEntries(rows)}
        style={{ flex: 1 }}
      />
    </Stack>
  );
}

const ReconciliationTable = styled(SimpleTable)`
  flex: 1;

  td:first-child {
    width: 64px;
  }

  td,
  th {
    text-align: left;
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  }
`;

const ReconciliationSummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
`;

interface ReconcileDialogProps {
  open: boolean;
  onClose: () => void;
}

function ReconcileDialog({ open, onClose }: ReconcileDialogProps) {
  const [loading, setLoading] = useState(false);

  const {
    toggledOffJournalEntries: toggledOffJournalEntryIds,
    toggledTransactions: toggledTransactionIds,
    journalEntries,
    transactions,
    selectedCheckpoint,
    account,
    checkpointEntriesTotal,
    reconcile,
    impliedEntries,
    runningDifference,
  } = useContext(ReconciliationContext);

  const reconcileAccount = async () => {
    const ignoreTransactionIds = [];
    const unignoreTransactionIds = [];

    const reverseJournalEntryIds = [];

    for (const [txId, on] of Object.entries(toggledTransactionIds)) {
      if (on) {
        unignoreTransactionIds.push(txId);
      } else {
        ignoreTransactionIds.push(txId);
      }
    }

    for (const jeId of toggledOffJournalEntryIds) {
      reverseJournalEntryIds.push(jeId);
    }

    try {
      setLoading(true);
      await reconcile({
        ignoreTransactionIds,
        unignoreTransactionIds,
        reverseJournalEntryIds,
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  const toggledOffDocumentJournalEntries = [];
  const toggledOffJournalEntries = [];
  for (const toggledOffJournalEntry of journalEntries || []) {
    if (!toggledOffJournalEntryIds.has(toggledOffJournalEntry.id)) {
      continue;
    }

    if (toggledOffJournalEntry.tags.find((t) => t.startsWith('document::'))) {
      toggledOffDocumentJournalEntries.push(toggledOffJournalEntry);
    } else {
      toggledOffJournalEntries.push(toggledOffJournalEntry);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth style={{}} maxWidth='lg'>
      <DialogTitle>
        <ThreeColumn align='center'>
          <div></div>
          <span>Reconcile</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack direction='row'>
          <Stack style={{ overflowY: 'auto' }} flex={1}>
            <Stack alignItems='stretch'>
              <Typography variant='h4'>Transactions</Typography>
              <ReconciliationTable>
                <thead>
                  <tr>
                    <th>Change</th>
                    <th>Transaction</th>
                  </tr>
                </thead>
                <tbody>
                  {!Object.keys(toggledTransactionIds).length && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        None
                      </td>
                    </tr>
                  )}
                  {transactions
                    ? transactions
                        .filter((tx) => toggledTransactionIds[tx.id] === false)
                        .map((tx) => {
                          return (
                            <tr key={tx.id}>
                              <td>Ignore</td>
                              <td>{tx.name}</td>
                            </tr>
                          );
                        })
                    : null}
                  {transactions
                    ? transactions
                        .filter((tx) => toggledTransactionIds[tx.id] === true)
                        .map((tx) => {
                          return (
                            <tr key={tx.id}>
                              <td>Unignore</td>
                              <td>{tx.name}</td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </ReconciliationTable>
            </Stack>

            <Stack alignItems='stretch'>
              <Typography variant='h4'>Documents</Typography>

              <ReconciliationTable>
                <thead>
                  <tr>
                    <th>Change</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>
                  {!toggledOffDocumentJournalEntries.length && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        None
                      </td>
                    </tr>
                  )}
                  {toggledOffDocumentJournalEntries.map((je) => {
                    return (
                      <tr key={je.id}>
                        <td>Ignore</td>
                        <td>{je.memo}</td>
                      </tr>
                    );
                  })}
                  <tr></tr>
                </tbody>
              </ReconciliationTable>
            </Stack>

            <Stack alignItems='stretch'>
              <Typography variant='h4'>Journal Entries</Typography>

              <ReconciliationTable>
                <thead>
                  <tr>
                    <th>Change</th>
                    <th>Journal Entry</th>
                  </tr>
                </thead>
                <tbody>
                  {!toggledOffJournalEntries.length && !impliedEntries.length && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        None
                      </td>
                    </tr>
                  )}
                  {toggledOffJournalEntries
                    .filter((je) => entryIsCurrent(je))
                    .map((je) => {
                      return (
                        <tr key={je.id}>
                          <td>Reverse</td>
                          <td>{je.memo}</td>
                        </tr>
                      );
                    })}
                  {impliedEntries.map((je) => {
                    return (
                      <tr key={je.id}>
                        <td>To be written</td>
                        <td>{je.memo}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </ReconciliationTable>
            </Stack>
          </Stack>

          <Divider orientation='vertical' flexItem />

          <Stack>
            <Typography variant='h3'>Summary</Typography>

            <Stack>
              <Typography variant='h4'>Transactions</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0}>
                  <Typography>Ignored</Typography>
                  <Typography>{Object.values(toggledTransactionIds).filter((t) => t === false).length}</Typography>
                </Stack>

                <Stack spacing={0}>
                  <Typography>Unignored</Typography>
                  <Typography>{Object.values(toggledTransactionIds).filter((t) => t === true).length}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>

            <Divider />

            <Stack>
              <Typography variant='h4'>Documents</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0}>
                  <Typography>Ignored</Typography>
                  <Typography>{Object.values(toggledOffDocumentJournalEntries).length}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>

            <Divider />

            <Stack>
              <Typography variant='h4'>Journal Entries</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0}>
                  <Typography>Reversed</Typography>
                  <Typography>{toggledOffJournalEntries.length}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>

            <Divider />

            <Stack>
              <Typography variant='h4'>Balance</Typography>
              <ReconciliationSummaryGrid>
                <Stack spacing={0} alignItems='end'>
                  <Typography>Previous Journal Balance</Typography>
                  <Typography>
                    {selectedCheckpoint !== null ? amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance)) : null}
                  </Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>New Journal Balance</Typography>
                  <Typography>{amountFormatter.format(checkpointEntriesTotal!)}</Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>Checkpoint</Typography>
                  <Typography>{selectedCheckpoint !== null ? amountFormatter.format(parseFloat(selectedCheckpoint.balance)) : null}</Typography>
                </Stack>

                <Stack spacing={0} alignItems='end'>
                  <Typography>Difference</Typography>
                  <Typography>{runningDifference !== null ? amountFormatter.format(runningDifference) : null}</Typography>
                </Stack>
              </ReconciliationSummaryGrid>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction='row' justifyContent='end'>
          <Button variant='contained' color='neutral' onClick={onClose}>
            Cancel
          </Button>

          <Button variant='contained' color='primary' onClick={reconcileAccount}>
            {loading ? <CircularProgress /> : 'Apply'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

function getSuggestionTitle(suggestion: AccountReconciliationSuggestion) {
  switch (suggestion.type) {
    case AccountReconciliationSuggestionType.UNREVIEWED_TRANSACTIONS:
      return 'Review Unreviewed Transactions';
    case AccountReconciliationSuggestionType.COVERAGE_GAP: {
      const data = suggestion.data as {
        coverageGaps: Array<{
          startDate: string;
          endDate: string;
        }>;
      };

      return data.coverageGaps.length > 1 ? 'Import Statements' : 'Import Statement';
    }
    case AccountReconciliationSuggestionType.DUPLICATE_TRANSACTIONS: {
      const data = suggestion.data as {
        duplicates: Array<string>;
      };

      return data.duplicates.length > 1 ? 'Potential Duplicate Transactions' : 'Potential Duplicate Transaction';
    }
    case AccountReconciliationSuggestionType.MANUAL_ENTRIES: {
      return `Potentially Problematic Manual Entries`;
    }
    case AccountReconciliationSuggestionType.NO_OPENING_BALANCES:
      return `Opening Balance Missing`;
    case AccountReconciliationSuggestionType.OPENING_BALANCE_FX:
      return `Possible Opening Balance FX Misalignment`;
    case AccountReconciliationSuggestionType.INFERRED_PAYMENTS:
      return `Inferred Document Expenses`;
    default:
      return 'Unknown';
  }
}

function getSuggestionDescription(suggestion: AccountReconciliationSuggestion, organization: Organization, account: Account) {
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  switch (suggestion.type) {
    case AccountReconciliationSuggestionType.UNREVIEWED_TRANSACTIONS:
      return `There are unreviewed transactions that fall within the time window of this checkpoint. Because they are unreviewed, they have not been written to the books and are not contributing to the journal balance.`;
    case AccountReconciliationSuggestionType.COVERAGE_GAP: {
      const data = suggestion.data as {
        coverageGaps: Array<{
          start: string;
          end: string;
        }>;
      };

      const parsedData = data.coverageGaps.map((g) => ({
        startDate: new Date(g.start),
        endDate: new Date(g.end),
      }));

      if (parsedData.length === 1) {
        const gap = parsedData[0];

        return `There is a coverage gap for this account from ${formatInTimeZone(gap.startDate, organization.timeZone || 'Etc/UTC', 'MMM d')} - ${formatInTimeZone(gap.endDate, organization.timeZone || 'Etc/UTC', 'MMM d')}. Transactions may have been missed in this gap. Import a statement to close the coverage gap and verify that the system has all transactions.`;
      } else {
        return (
          `There are multiple coverage gaps for this account. Transactions may have been missed in these gaps. Import a statement to close the coverage gap and verify that the system has all transactions.` +
          `\n\nCoverage Gaps:\n` +
          parsedData
            .map(
              (g) =>
                `\n${formatInTimeZone(g.startDate, organization.timeZone || 'Etc/UTC', 'MMM d')} - ${formatInTimeZone(g.endDate, organization.timeZone || 'Etc/UTC', 'MMM d')}`
            )
            .join('\n')
        );
      }
    }
    case AccountReconciliationSuggestionType.DUPLICATE_TRANSACTIONS: {
      const data = suggestion.data as {
        duplicates: Array<{
          id: string;
          type: string;
          amount: string;
        }>;
      };

      let description = `${data.duplicates.length} transaction(s) have been detected as potential duplicates. `;

      const duplicateSum = data.duplicates.reduce((sum, current) => {
        return sum + parseFloat(current.amount) * -1;
      }, 0);

      if (data.duplicates.find((t) => t.type === 'expense') && data.duplicates.find((t) => t.type === 'income')) {
        description += `These potential duplicates contain a mix of income and expense transactions, and total to an amount of ${amountFormatter.format(duplicateSum)}`;
      } else if (data.duplicates.find((t) => t.type === 'expense')) {
        description += `These potential duplicates only include expenses, and are lowering the journal balance by ${amountFormatter.format(duplicateSum * -1)}`;
      } else {
        description += `These potential duplicates only include income, and are raising the journal balance by ${amountFormatter.format(duplicateSum)}`;
      }

      return description;
    }
    case AccountReconciliationSuggestionType.MANUAL_ENTRIES: {
      return `Manual entries detected that match the difference between checkpoint and journal balances. This suggests that the manual entries are problematic.`;
    }
    case AccountReconciliationSuggestionType.NO_OPENING_BALANCES:
      return `This account has no opening balance. This might not be a problem if the account was opened this FY, but most accounts typically do have an opening balance.`;
    case AccountReconciliationSuggestionType.OPENING_BALANCE_FX:
      return `This is a foreign currency account with no other obvious issues causing a difference between the journal balance and checkpoint balance. Is it possible that the opening balance for this account was the result of incorrectly converting a CAD balance?`;
    case AccountReconciliationSuggestionType.INFERRED_PAYMENTS:
      return `There are unmatched documents for which payments have been inferred. These inferred amounts can disappear in the future if these documents are matched. The best way of dealing with these is to match the documents with transactions, however removing the payment details will also remove the inferred payment and safeguard against this disappearing in the future.`;
    default:
      return 'Unknown';
  }
}

function getSuggestionActionDescription(suggestion: AccountReconciliationSuggestion) {
  switch (suggestion.type) {
    case AccountReconciliationSuggestionType.UNREVIEWED_TRANSACTIONS:
      return 'Click to review transactions';
    case AccountReconciliationSuggestionType.COVERAGE_GAP:
      return 'Click to import statements';
    case AccountReconciliationSuggestionType.DUPLICATE_TRANSACTIONS:
      return 'Click to stage changes (ignore duplicates)';
    case AccountReconciliationSuggestionType.MANUAL_ENTRIES:
      return 'Click to stage changes (reverse manual entries)';
    case AccountReconciliationSuggestionType.NO_OPENING_BALANCES:
      return 'Click to create opening balances';
    case AccountReconciliationSuggestionType.OPENING_BALANCE_FX:
      return 'Click to view opening balances';
    case AccountReconciliationSuggestionType.INFERRED_PAYMENTS:
      return 'Click to match documents';
    default:
      return 'Unknown';
  }
}

function getSuggestionAction(
  suggestion: AccountReconciliationSuggestion,
  onOpenStatementImport: () => void,
  onOpenEntryEditor: (entryId?: string) => void,
  onToggleOffTransactions: (txIds: string[]) => void,
  onToggleOffJournalEntries: (entryIds: string[]) => void
) {
  switch (suggestion.type) {
    case AccountReconciliationSuggestionType.UNREVIEWED_TRANSACTIONS: {
      const data = suggestion.data as {
        transactionIds: string[];
      };

      const transactionIds = data.transactionIds.map((txId) => `transactionIds=${txId}`).join('&');

      return () => window.open(`/admin/transactions?tab=UNREVIEWED&${transactionIds}`, '_blank');
    }
    case AccountReconciliationSuggestionType.COVERAGE_GAP: {
      return () => onOpenStatementImport();
    }
    case AccountReconciliationSuggestionType.DUPLICATE_TRANSACTIONS: {
      const data = suggestion.data as {
        duplicates: Array<{
          id: string;
          type: string;
          amount: string;
        }>;
      };

      return () => onToggleOffTransactions(data.duplicates.map((tx) => tx.id));
    }
    case AccountReconciliationSuggestionType.MANUAL_ENTRIES: {
      const data = suggestion.data as {
        entryIds: string[];
      };

      return () => onToggleOffJournalEntries(data.entryIds);
    }
    case AccountReconciliationSuggestionType.NO_OPENING_BALANCES:
      return () => onOpenEntryEditor();
    case AccountReconciliationSuggestionType.OPENING_BALANCE_FX: {
      const data = suggestion.data as {
        openingBalanceEntryId: string;
      };

      return () => onOpenEntryEditor(data.openingBalanceEntryId);
    }
    case AccountReconciliationSuggestionType.INFERRED_PAYMENTS: {
      const data = suggestion.data as {
        documentIds: Array<string>;
      };

      const documentIds = data.documentIds.map((did) => `documentIds=${did}`).join('&');

      return () => window.open(`/admin/documents?tab=ALL&${documentIds}`, '_blank');
    }
  }
}

function SuggestionIcon({ data }: { data: AccountReconciliationSuggestion }) {
  const theme = useTheme();
  const iconColor = theme.palette.mode === 'dark' ? alpha('#fff', 0.04) : alpha('#000', 0.04);

  switch (data.type) {
    case AccountReconciliationSuggestionType.UNREVIEWED_TRANSACTIONS:
      return <InfoCircle variant='Bold' size='6rem' color={iconColor} />;
    case AccountReconciliationSuggestionType.COVERAGE_GAP:
      return <TableDocument variant='Bold' size='6rem' color={iconColor} />;
    case AccountReconciliationSuggestionType.DUPLICATE_TRANSACTIONS:
      return <DocumentCopy variant='Bold' size='6rem' color={iconColor} />;
    case AccountReconciliationSuggestionType.MANUAL_ENTRIES:
      return <Book variant='Bold' size='6rem' color={iconColor} />;
    case AccountReconciliationSuggestionType.NO_OPENING_BALANCES:
      return <Book variant='Bold' size='6rem' color={iconColor} />;
    case AccountReconciliationSuggestionType.INFERRED_PAYMENTS:
      return <DocumentText variant='Bold' size='6rem' color={iconColor} />;
  }
}

interface SuggestionProps {
  data: AccountReconciliationSuggestion;
  account: Account;
  organization: Organization;
  onOpenStatementImport: () => void;
  onOpenEntryEditor: (entryId?: string) => void;
  onToggleOffTransactions: (txIds: string[]) => void;
  onToggleOffJournalEntries: (entryIds: string[]) => void;
  className?: string;
}

const Suggestion = styled(
  ({
    data,
    account,
    organization,
    onOpenStatementImport,
    onOpenEntryEditor,
    onToggleOffTransactions,
    onToggleOffJournalEntries,
    className,
  }: SuggestionProps) => {
    const theme = useTheme();

    return (
      <Stack
        onClick={getSuggestionAction(data, onOpenStatementImport, onOpenEntryEditor, onToggleOffTransactions, onToggleOffJournalEntries)}
        className={className}
      >
        <Box
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: `translateY(-50%)`,
          }}
        >
          <SuggestionIcon data={data} />
        </Box>
        <Typography variant='h4'>{getSuggestionTitle(data)}</Typography>
        <Typography whiteSpace='pre-wrap'>{getSuggestionDescription(data, organization, account)}</Typography>
        <Stack
          alignItems='center'
          justifyContent='center'
          paddingTop={SMALL_VERTICAL_SPACING}
          style={{ borderTop: `1px solid ${theme.palette.border.main}` }}
        >
          <Typography variant='small' align='center' whiteSpace='pre'>
            {getSuggestionActionDescription(data)}
          </Typography>
        </Stack>
      </Stack>
    );
  }
)`
  position: relative;
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  border: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
  padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => (theme.palette.mode === 'dark' ? alpha('#fff', 0.04) : alpha('#000', 0.04))};
  }
`;

interface SuggestionDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

function SuggestionDrawer({ open, onClose, onOpen }: SuggestionDrawerProps) {
  const [statementImportDialogOpen, setStatementImportDialogOpen] = useState(false);
  const [entryEditorOpenFor, setEntryEditorOpenFor] = useState<JournalEntry | null | undefined>(null);
  const [journalEntryLoading, setJournalEntryLoading] = useState(false);
  const {
    reconciliationSuggestions,
    journal,
    organization,
    accounts,
    account,
    setTransactionToggle,
    setJournalEntryToggle,
    createJournalEntry,
    journalEntries,
  } = useContext(ReconciliationContext);

  const accountsById = accounts?.reduce(
    (map, current) => {
      map[current.id] = current;
      return map;
    },
    {} as { [id: string]: Account }
  );

  const createEntry = useCallback(
    async (entry: CreateJournalEntryArgs) => {
      try {
        setJournalEntryLoading(true);

        await createJournalEntry(entry);

        setEntryEditorOpenFor(null);
      } finally {
        setJournalEntryLoading(false);
      }
    },
    [createJournalEntry]
  );

  return (
    <SwipeableDrawer open={open} onClose={onClose} onOpen={onOpen} anchor='right'>
      <Stack
        alignItems='stretch'
        paddingTop={SMALL_VERTICAL_SPACING}
        paddingBottom={SMALL_VERTICAL_SPACING}
        paddingLeft={SMALL_HORIZONTAL_SPACING}
        paddingRight={SMALL_HORIZONTAL_SPACING}
        maxWidth={400}
        height='100%'
      >
        <ThreeColumn align='center' style={{ width: '100%' }}>
          <span></span>
          <Typography variant='h3'>Suggestions</Typography>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>

        {reconciliationSuggestions?.length === 0 && (
          <Stack flex={1} justifyContent='center' alignContent='center'>
            <Typography textAlign='center'>No suggestions</Typography>
          </Stack>
        )}

        {reconciliationSuggestions && organization && account ? (
          reconciliationSuggestions.map((suggestion, i) => (
            <Suggestion
              key={i}
              data={suggestion}
              account={account}
              organization={organization}
              onOpenStatementImport={() => setStatementImportDialogOpen(true)}
              onOpenEntryEditor={(entryId) => {
                if (entryId === undefined) {
                  setEntryEditorOpenFor(undefined);
                } else {
                  const entry = journalEntries?.find((e) => e.id === entryId) || null;
                  setEntryEditorOpenFor(entry);
                }
              }}
              onToggleOffTransactions={(txIds) => {
                for (const txId of txIds) {
                  setTransactionToggle(txId, false);
                }
              }}
              onToggleOffJournalEntries={(entryIds) => {
                for (const entryId of entryIds) {
                  setJournalEntryToggle(entryId, false);
                }
              }}
            />
          ))
        ) : (
          <CircularProgress />
        )}

        {organization && accounts && account && (
          <ImportTransactionsDialog
            open={statementImportDialogOpen}
            onClose={() => setStatementImportDialogOpen(false)}
            organization={organization}
            accounts={accounts}
            preselectAccount={account}
          />
        )}

        {journal && accountsById && (
          <JournalEntryDialog
            loading={journalEntryLoading}
            open={entryEditorOpenFor !== null}
            onClose={() => setEntryEditorOpenFor(null)}
            journal={journal}
            journalAccountsById={accountsById}
            onCreate={createEntry}
            existingDetails={entryEditorOpenFor || undefined}
            preselectOpeningBalance={entryEditorOpenFor === undefined ? true : undefined}
          />
        )}
      </Stack>
    </SwipeableDrawer>
  );
}

export function ReconciliationAccountView() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    organization,
    accounts,
    account,
    journal,
    accountCoverage,
    accountCheckpoints,
    selectedCheckpoint,
    updateSelectedCheckpoint,
    checkpointEntriesTotal,
    toggledOffJournalEntries,
    runningDifference,
    revertToggles,
    impliedEntries,
    checkpointEntries,
    reconciliationSuggestions,
    createCheckpoint,
  } = useContext(ReconciliationContext);

  const [confirmRevertChangesDialogOpen, setConfirmRevertChangesDialogOpen] = useState(false);
  const [reconcileDialogOpen, setReconcileDialogOpen] = useState(false);
  const [suggestionDrawerOpen, setSuggestionDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createCheckpointDialogOpen, setCreateCheckpointDialogOpen] = useState(false);

  useEffect(() => {
    updateSelectedCheckpoint((current) => {
      if (current) return current;
      if (!accountCheckpoints) return null;

      let lastReconciledIndex = -1;

      for (let i = 0; i < accountCheckpoints.length; i++) {
        if (accountCheckpoints[i].reconciled) {
          lastReconciledIndex = i;
        }
      }

      return accountCheckpoints.find((_, i) => i > lastReconciledIndex && !accountCheckpoints[i].reconciled) || null;
    });
  }, [accountCheckpoints, updateSelectedCheckpoint]);

  const revertChanges = () => {
    revertToggles();
    setConfirmRevertChangesDialogOpen(false);
  };

  const createManualCheckpoint = async (balance: number, date: string) => {
    try {
      setLoading(true);

      await createCheckpoint(balance, date);

      setCreateCheckpointDialogOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account?.externalCurrency || 'CAD' });

  if (!accountCoverage || !accountCoverage || !accountCheckpoints || !journal || !account || !accounts || !organization) {
    return (
      <Stack justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Stack>
    );
  }

  const cashAndCreditAccounts = accounts.filter((a) =>
    [StandardAccounts.CASH, StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS, StandardAccounts.LINE_OF_CREDIT].includes(a.standardAccount)
  );

  const numChanges =
    (checkpointEntries || []).filter((je) => {
      return entryIsCurrent(je) && toggledOffJournalEntries.has(je.id);
    }).length + impliedEntries.length;

  return (
    <Stack flex={1} minHeight={0} spacing={SMALL_VERTICAL_SPACING}>
      <Stack direction='row' alignItems='center' spacing={SMALL_HORIZONTAL_SPACING}>
        <MuiButton
          sx={{
            width: 'auto',
          }}
          variant='text'
          onClick={() => navigate('/admin/reconciliation')}
        >
          <ArrowLeft2 variant='Bold' size='1rem' />
          Back
        </MuiButton>

        <AccountSelect
          accounts={cashAndCreditAccounts}
          selected={account}
          onSelect={(account) => navigate(`/admin/reconciliation/accounts/${account.id}`)}
        />

        <CheckpointSelect
          organization={organization}
          checkpoints={accountCheckpoints}
          selected={selectedCheckpoint}
          onSelect={(checkpoint) => updateSelectedCheckpoint(checkpoint)}
        />

        <Tooltip title='Create Checkpoint'>
          <span>
            <IconButton onClick={() => setCreateCheckpointDialogOpen(true)}>
              <AddCircle color={theme.palette.primary.main} />
            </IconButton>
          </span>
        </Tooltip>

        <CreateCheckpointDialog
          loading={loading}
          organization={organization}
          journal={journal}
          open={createCheckpointDialogOpen}
          onClose={() => setCreateCheckpointDialogOpen(false)}
          onCreate={createManualCheckpoint}
        />

        <AccountCoverage
          account={account}
          organization={organization}
          journal={journal}
          coverage={accountCoverage}
          checkpoints={accountCheckpoints}
          selectedCheckpoint={selectedCheckpoint}
          onCheckpointSelect={(checkpoint) => {
            updateSelectedCheckpoint(checkpoint);
          }}
          style={{
            marginTop: 0,
            height: 64,
            flex: 1,
          }}
        />

        <Stack direction='row' spacing={SMALL_HORIZONTAL_SPACING}>
          <Button variant='outlined' color='primary' onClick={() => setConfirmRevertChangesDialogOpen(true)}>
            Revert
          </Button>
          <ConfirmDialog
            open={confirmRevertChangesDialogOpen}
            onClose={() => setConfirmRevertChangesDialogOpen(false)}
            onConfirm={revertChanges}
            onConfirmLabel='Revert'
            message='Are you sure you want to revert changes?'
          />

          <Button variant='contained' color='primary' onClick={() => setReconcileDialogOpen(true)}>
            Reconcile
          </Button>
          <ReconcileDialog open={reconcileDialogOpen} onClose={() => setReconcileDialogOpen(false)} />

          <Button variant='outlined' color='primary' onClick={() => setSuggestionDrawerOpen(true)}>
            <TipsAndUpdates />
            {reconciliationSuggestions?.length !== 0 && (
              <Chip
                size='small'
                sx={{
                  maxHeight: '1rem',
                  fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                  marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                }}
                label={reconciliationSuggestions?.length}
                color='warning'
              />
            )}
          </Button>
          <SuggestionDrawer open={suggestionDrawerOpen} onClose={() => setSuggestionDrawerOpen(false)} onOpen={() => setSuggestionDrawerOpen(true)} />
        </Stack>
      </Stack>

      <Stack direction='row' justifyContent='stretch' spacing={SMALL_HORIZONTAL_SPACING}>
        <MetricCard
          title='Checkpoint balance'
          primaryContent={
            <Typography variant='h3'>{selectedCheckpoint ? amountFormatter.format(parseFloat(selectedCheckpoint.balance)) : null}</Typography>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Journal balance'
          primaryContent={
            <Typography variant='h3'>{checkpointEntriesTotal !== null ? amountFormatter.format(checkpointEntriesTotal) : null}</Typography>
          }
          secondaryContent={
            <>
              {selectedCheckpoint &&
                checkpointEntriesTotal !== null &&
                Math.abs(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance)) > 0.01 && (
                  <Tooltip title={`${amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance) - checkpointEntriesTotal)} toggled off`}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Edit2 size='1rem' />
                      <Typography>{amountFormatter.format(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance))}</Typography>
                    </Stack>
                  </Tooltip>
                )}
            </>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Difference'
          primaryContent={
            <Typography
              variant='h3'
              color={!runningDifference || Math.abs(runningDifference) < 0.01 ? theme.palette.primary.main : theme.palette.error.main}
            >
              {runningDifference !== null && runningDifference > 0 ? '+' : ''}
              {runningDifference !== null ? amountFormatter.format(runningDifference) : null}
            </Typography>
          }
          secondaryContent={
            <>
              {checkpointEntriesTotal !== null &&
                runningDifference !== null &&
                selectedCheckpoint?.difference &&
                Math.abs(runningDifference - parseFloat(selectedCheckpoint.difference)) > 0.01 && (
                  <Tooltip title={`${amountFormatter.format(parseFloat(selectedCheckpoint.journalBalance) - checkpointEntriesTotal)} toggled off`}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Edit2 size='1rem' />
                      <Typography>{amountFormatter.format(checkpointEntriesTotal - parseFloat(selectedCheckpoint.journalBalance))}</Typography>
                    </Stack>
                  </Tooltip>
                )}
            </>
          }
          style={{
            flex: 1,
          }}
        />

        <MetricCard
          title='Changes pending'
          primaryContent={<Typography variant='h3'>{numChanges}</Typography>}
          style={{
            flex: 1,
          }}
        />
      </Stack>

      <Stack direction='row' flex={1} minHeight={0} spacing={SMALL_HORIZONTAL_SPACING}>
        <TransactionsTable style={{ minWidth: 0, flex: 1 }} />
        <Divider orientation='vertical' />
        <JournalEntriesTable style={{ minWidth: 0, flex: 1 }} />
      </Stack>
    </Stack>
  );
}
