import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AddCircle, Clock, CloseCircle, CloseSquare, Copy, CopySuccess, TickCircle } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Account, AsyncJobStatus, Organization, Statement, StatementType, Transaction, TransactionImport, useAdmin } from '../../../api';
import { Button, ConfirmDialog, Search, StatementTypeSelect } from '../../../components';
import { getTimezoneAbbreviation } from '../../../utils/date-utils';

const useAdminData = (organization?: Organization | null) => {
  const { fetchTransactionImports, transactionImports } = useAdmin();

  useEffect(() => {
    if (!organization) {
      return;
    }

    fetchTransactionImports(organization.id!).catch((e) => {
      throw e;
    });

    const refreshInterval = setInterval(() => {
      fetchTransactionImports(organization.id!).catch((e) => {
        throw e;
      });
    }, 5000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [fetchTransactionImports, organization]);

  const imports = useMemo(() => {
    if (!organization) {
      return [];
    }

    return transactionImports[organization.id!] || [];
  }, [transactionImports, organization]);

  return {
    transactionImports: imports,
  };
};

interface StatementUploadSelectProps {
  onUpload: () => void;
  account: string;
  onAccountChange: (accountId: string) => void;
  file: File | null;
  onFileChange: (file: File | null) => void;
  statementType: StatementType;
  onStatementTypeChange: (type: string) => void;
  accounts: Account[];
}

function StatementUploadSelect({
  file,
  onFileChange,
  statementType,
  onStatementTypeChange,
  onUpload,
  onAccountChange,
  account,
  accounts,
}: StatementUploadSelectProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Stack direction='row'>
      {file && (
        <Stack>
          <Typography>{file.name}</Typography>
          <Stack direction='row'>
            <Button variant='contained' color='neutral' onClick={() => onFileChange(null)}>
              Clear
            </Button>
            <Button variant='contained' color='primary' onClick={() => onUpload()}>
              Generate Preview
            </Button>
          </Stack>
        </Stack>
      )}

      {!file && (
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Select File
          <input type='file' hidden ref={inputRef} onChange={(event) => onFileChange(event.target.files![0])} />
        </Button>
      )}

      <FormControl style={{ minWidth: 200 }}>
        <InputLabel id='statementTypeSelect'>Type</InputLabel>
        <StatementTypeSelect
          label='Type'
          labelId='statementTypeSelect'
          statementType={statementType}
          onStatementTypeChange={(statementType) => onStatementTypeChange(statementType)}
        />
      </FormControl>

      <FormControl style={{ minWidth: 200 }}>
        <InputLabel id='statementAccountSelect'>Account</InputLabel>
        <Select label='Account' labelId='statementAccountSelect' value={account} onChange={(event) => onAccountChange(event.target.value)}>
          <MenuItem value={''}>None</MenuItem>
          {accounts.map((a) => {
            return (
              <MenuItem key={a.externalId} value={a.externalId!}>
                {a.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}

function TransactionImportPreviewText({
  params,
  duplicateMap,
  importOverrides,
}: {
  params: GridRenderCellParams;
  duplicateMap: { [transactionId: string]: Transaction };
  importOverrides: { [transactionId: string]: boolean };
}) {
  const theme = useTheme();
  const transaction = params.row as Transaction;
  const duplicate = duplicateMap[transaction.id];

  return (
    <span
      style={{
        color:
          (duplicate && importOverrides[transaction.id] !== true) || importOverrides[transaction.id] === false ? '#888' : theme.palette.text.primary,
        fontStyle: (duplicate && importOverrides[transaction.id] !== true) || importOverrides[transaction.id] === false ? 'italic' : 'normal',
      }}
    >
      {params.colDef.field === 'date' || params.colDef.field === 'duplicateDate'
        ? params.value
          ? formatInTimeZone(params.value as Date, 'UTC', 'MMM d, yyyy')
          : params.value
        : params.value}
    </span>
  );
}

interface TransactionImportPreviewTableProps {
  organization: Organization;
  transactions: Transaction[];
  duplicateMap: { [transactionId: string]: Transaction };
  importOverrides: { [transactionId: string]: boolean };
  onMarkDuplicate: (transactionId: string) => void;
  onMarkForImport: (transactionId: string) => void;
  style?: CSSProperties;
}

function TransactionImportPreviewTable({
  organization,
  transactions,
  duplicateMap,
  importOverrides,
  onMarkDuplicate,
  onMarkForImport,
  style,
}: TransactionImportPreviewTableProps) {
  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'type',
      headerName: 'Type',
      valueGetter: (_value, row) => (parseFloat((row as Transaction).amount) < 0 ? 'Income' : 'Expense'),
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'date',
      headerName: `Date (${getTimezoneAbbreviation(organization.timeZone)})`,
      width: 120,
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (_value, row) => {
        const transaction = row as Transaction;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

        const floatAmount = parseFloat(transaction.amount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'duplicateName',
      headerName: 'Name',
      valueGetter: (_value, row) => {
        const transaction = row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return duplicate.name;
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'duplicateDate',
      headerName: 'Date',
      valueGetter: (_value, row) => {
        const transaction = row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return duplicate.date;
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'duplicateType',
      headerName: 'Type',
      valueGetter: (_value, row) => {
        const transaction = row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return parseFloat(duplicate.amount) < 0 ? 'Income' : 'Expense';
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'duplicateAmount',
      headerName: 'Amount',
      valueGetter: (_value, row) => {
        const transaction = row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: duplicate.isoCurrencyCode });

        const floatAmount = parseFloat(duplicate.amount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      display: 'flex',
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if ((duplicate && importOverrides[transaction.id] !== true) || (!duplicate && importOverrides[transaction.id] === false)) {
          return (
            <Button variant='contained' color='primary' onClick={() => onMarkForImport(transaction.id)}>
              Mark for Import
            </Button>
          );
        } else {
          return (
            <Button variant='contained' color='primary' onClick={() => onMarkDuplicate(transaction.id)}>
              Do not import
            </Button>
          );
        }
      },
    },
  ];

  return (
    <DataGrid
      columnGroupingModel={[
        {
          groupId: 'Parsed Transaction',
          children: [{ field: 'name' }, { field: 'date' }, { field: 'type' }, { field: 'amount' }],
        },
        {
          groupId: 'Potential Existing Duplicate',
          children: [{ field: 'duplicateName' }, { field: 'duplicateDate' }, { field: 'duplicateType' }, { field: 'duplicateAmount' }],
        },
      ]}
      style={style}
      rows={transactions}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
      pageSizeOptions={[50]}
    />
  );
}

interface ImportTransactionsDialogProps {
  open: boolean;
  onClose: () => void;
  afterImport?: () => void;
  style?: CSSProperties;
  organization: Organization;
  accounts: Account[];
  preselectAccount?: Account;
  preselectStatement?: Statement;
}

export function ImportTransactionsDialog({
  open,
  onClose,
  afterImport,
  organization,
  accounts,
  preselectAccount,
  preselectStatement,
}: ImportTransactionsDialogProps) {
  const theme = useTheme();
  const { createTransactionImportPreview, importTransactions } = useAdmin();

  const accountsWithExternalIds = accounts.filter((a) => a.externalId);

  const [statementFile, setStatementFile] = useState<File | null>(null);
  const [statementType, setStatementType] = useState(StatementType.RBC_CANADA);
  const [accountExternalId, setAccountExternalId] = useState<string>('');
  const [parsedTransactions, setParsedTransactions] = useState<Transaction[]>([]);
  const [duplicateMap, setDuplicateMap] = useState<{ [transactionId: string]: Transaction }>({});
  const [openingBalance, setOpeningBalance] = useState<{ date: Date; amount: string; currency: string } | null>(null);
  const [closingBalance, setClosingBalance] = useState<{ date: Date; amount: string; currency: string } | null>(null);
  const [importOverrides, setImportOverrides] = useState<{ [transactionId: string]: boolean }>({});
  const [loading, setLoading] = useState(false);
  const [showWarningDialogFor, setShowWarningDialogFor] = useState<{ duplicateStatement?: boolean } | null>(null);

  const balanceFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: openingBalance?.currency || 'CAD' });

  const createPreviewLock = useRef(false);
  const createPreview = useCallback(async () => {
    if ((!statementFile && !preselectStatement) || !accountExternalId || createPreviewLock.current) {
      return;
    }

    try {
      createPreviewLock.current = true;

      setLoading(true);
      const statement = preselectStatement ? { id: preselectStatement.id } : { file: statementFile! };
      const transactionImport = await createTransactionImportPreview({
        organizationId: organization.id!,
        statement,
        statementType,
        accountId: accountExternalId,
      });

      setParsedTransactions(transactionImport.transactions);

      setDuplicateMap(transactionImport.duplicateMap);

      setOpeningBalance(transactionImport.openingBalance || null);
      setClosingBalance(transactionImport.closingBalance || null);
    } finally {
      createPreviewLock.current = false;
      setLoading(false);
    }
  }, [createTransactionImportPreview, statementFile, preselectStatement, accountExternalId, statementType, organization]);

  useEffect(() => {
    setStatementFile(null);
    setStatementType(preselectStatement?.statementType ? preselectStatement.statementType : StatementType.RBC_CANADA);
    setParsedTransactions([]);
    setDuplicateMap({});
    setImportOverrides({});
    setLoading(false);
    setShowWarningDialogFor(null);
    setOpeningBalance(null);
    setClosingBalance(null);

    setAccountExternalId(preselectAccount?.externalId || '');
  }, [open, preselectAccount, preselectStatement]);

  useEffect(() => {
    if (open && preselectStatement) {
      createPreview().catch((e) => {
        throw e;
      });
    }
  }, [open, preselectStatement, createPreview]);

  const performImport = async (ignoreWarnings?: boolean) => {
    if ((!statementFile && !preselectStatement) || !accountExternalId) {
      return;
    }

    try {
      setLoading(true);
      const transactionsToImport = parsedTransactions.filter((t) => {
        return (!duplicateMap[t.id] || importOverrides[t.id]) && importOverrides[t.id] !== false;
      });

      const statement = preselectStatement ? { id: preselectStatement.id } : { file: statementFile! };

      const warnings = await importTransactions({
        organizationId: organization.id!,
        transactions: transactionsToImport,
        accountId: accountExternalId,
        statement,
        statementType,
        ignoreWarnings: ignoreWarnings || false,
        openingBalance: openingBalance || undefined,
        closingBalance: closingBalance || undefined,
      });
      if (warnings && !ignoreWarnings) {
        setShowWarningDialogFor(warnings);
      } else {
        setShowWarningDialogFor(null);
        setParsedTransactions([]);
        setDuplicateMap({});
        setImportOverrides({});
        setStatementFile(null);
        onClose();

        if (afterImport) {
          afterImport();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const markDuplicate = (transactionId: string) => {
    setImportOverrides((existing) => {
      return {
        ...existing,
        [transactionId]: false,
      };
    });
  };

  const markForImport = (transactionId: string) => {
    setImportOverrides((existing) => {
      return {
        ...existing,
        [transactionId]: true,
      };
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <span>Import Transactions</span>

          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
        }}
      >
        <Stack style={{ flex: 1 }} paddingTop={theme.spacing(5)} overflow='hidden'>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            {preselectStatement ? (
              <Box>{preselectStatement.name}</Box>
            ) : (
              <StatementUploadSelect
                account={accountExternalId}
                onAccountChange={(id) => setAccountExternalId(id)}
                statementType={statementType}
                onStatementTypeChange={(type) => setStatementType(type as StatementType)}
                file={statementFile}
                onFileChange={(file) => setStatementFile(file)}
                onUpload={createPreview}
                accounts={accountsWithExternalIds}
              />
            )}

            <Stack spacing={0} alignItems='end'>
              <Typography>Opening Balance: {openingBalance ? balanceFormatter.format(parseFloat(openingBalance.amount)) : 'N/A'}</Typography>
              <Typography>Closing Balance: {closingBalance ? balanceFormatter.format(parseFloat(closingBalance.amount)) : 'N/A'}</Typography>
            </Stack>
          </Stack>
          <Typography variant='h4'>Preview</Typography>
          <TransactionImportPreviewTable
            organization={organization}
            transactions={parsedTransactions}
            duplicateMap={duplicateMap}
            importOverrides={importOverrides}
            onMarkDuplicate={markDuplicate}
            onMarkForImport={markForImport}
          />
          <ConfirmDialog
            open={!!showWarningDialogFor}
            onClose={() => setShowWarningDialogFor(null)}
            content={
              <Stack>
                {showWarningDialogFor &&
                  Object.keys(showWarningDialogFor).map((warning) => (
                    <Alert severity='warning' key={warning}>
                      <AlertTitle>Warning</AlertTitle>
                      <Typography>
                        {warning === 'duplicateStatement' &&
                          'It looks like a similar statement is already in the system. Are you sure you want to import these transactions?'}
                      </Typography>
                    </Alert>
                  ))}
              </Stack>
            }
            onConfirm={() => performImport(true)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='neutral'
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => performImport()}
          disabled={(!parsedTransactions.length && !openingBalance && !closingBalance) || loading}
        >
          {loading ? <CircularProgress /> : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const getJobLabel = (transactionImport: TransactionImport) => {
  switch (transactionImport.status) {
    case AsyncJobStatus.NOT_STARTED:
      return 'Not Started';
    case AsyncJobStatus.IN_PROGRESS:
      return 'In Progress';
    case AsyncJobStatus.COMPLETED:
      return 'Complete';
    case AsyncJobStatus.FAILED:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

interface TransactionImportsTableProps {
  transactionImports: TransactionImport[];
  style?: CSSProperties;
}

function TransactionImportsTable({ transactionImports, style }: TransactionImportsTableProps) {
  const theme = useTheme();
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (id: string) => {
    setIdCopied(id);
    await navigator.clipboard.writeText(id);
  }, []);

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        return (
          <Tooltip title={transactionImport.id}>
            <span>
              <IconButton onClick={() => copy(transactionImport.id)}>
                {idCopied === transactionImport.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      display: 'flex',
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        const getJobIcon = () => {
          switch (transactionImport.status) {
            case AsyncJobStatus.NOT_STARTED:
              return <Clock size='1rem' />;
            case AsyncJobStatus.IN_PROGRESS:
              return <CircularProgress size='1rem' />;
            case AsyncJobStatus.COMPLETED:
              return <TickCircle color={theme.palette.primary.main} size='1rem' />;
            case AsyncJobStatus.FAILED:
              return <CloseSquare color={theme.palette.error.main} size='1rem' />;
            default:
              return 'Unknown';
          }
        };

        return (
          <Stack direction='row' spacing={2} alignItems='center'>
            {getJobIcon()}
            <span>{getJobLabel(transactionImport)}</span>
          </Stack>
        );
      },
    },
    {
      display: 'flex',
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        if (transactionImport.statement) {
          return transactionImport.statement.fileName;
        } else {
          return 'Unknown';
        }
      },
    },
    {
      display: 'flex',
      field: 'statement',
      headerName: 'Statement',
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        let iconSrc: string;
        if (transactionImport.statement) {
          iconSrc = transactionImport.statement.type === 'application/pdf' ? '/pdf-file-icon.svg' : '/csv.svg';
        } else {
          return null;
        }

        return (
          <Box padding={theme.spacing(2)}>
            <Link href={transactionImport.statement.url} target='_blank'>
              <img src={iconSrc} alt={transactionImport.statement.fileName} width='32px' height='32px' />
            </Link>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'created',
      headerName: 'Import Date',
      width: 150,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        return (
          <Tooltip title={transactionImport.created.toString()}>
            <span>{format(transactionImport.created, 'MMM d, yyyy')}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataGrid
      columnGroupingModel={[
        {
          groupId: 'Parsed Transaction',
          children: [{ field: 'name' }, { field: 'date' }, { field: 'type' }, { field: 'amount' }],
        },
        {
          groupId: 'Potential Existing Duplicate',
          children: [{ field: 'duplicateName' }, { field: 'duplicateDate' }, { field: 'duplicateType' }, { field: 'duplicateAmount' }],
        },
      ]}
      style={style}
      rows={transactionImports}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [{ field: 'created', sort: 'desc' }],
        },
      }}
      pageSizeOptions={[50]}
    />
  );
}

export interface TransactionImportsProps {
  organization: Organization;
  accounts: Account[];
  style?: CSSProperties;
}
export function TransactionImports({ organization, accounts }: TransactionImportsProps) {
  const { transactionImports } = useAdminData(organization);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

  const filteredImports = useMemo(() => {
    if (!searchCriteria.length) {
      return transactionImports;
    }

    return transactionImports.filter((t) => {
      for (const searchCriterion of searchCriteria) {
        if (`id::${t.id}` === searchCriterion) {
          return true;
        }

        if (t.id.includes(searchCriterion)) {
          return true;
        }

        if (t.statement && t.statement.fileName.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const status = getJobLabel(t);
        if (status.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const formattedDate = format(t.created, 'MMM d, yyyy');
        if (formattedDate.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }
      }

      return false;
    });
  }, [searchCriteria, transactionImports]);

  return (
    <Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} style={{ flex: 1 }} />

        <Button variant='contained' color='primary' onClick={() => setShowImportDialog(true)}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <AddCircle variant='Bold' size='1.1rem' />
            <span>Import Transactions</span>
          </Stack>
        </Button>
      </Stack>

      <TransactionImportsTable
        transactionImports={filteredImports}
        style={{
          flex: 1,
        }}
      />

      <ImportTransactionsDialog
        open={showImportDialog}
        onClose={() => setShowImportDialog(false)}
        organization={organization}
        accounts={accounts}
        style={{
          flex: 1,
        }}
      />
    </Stack>
  );
}
