import { Checkbox, FormControlLabel, FormGroup, FormLabel, Menu, PopoverVirtualElement, Stack, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { Organization } from '../../../api';
import { Button } from '../../../components';
import { ZonedDatePicker } from '../../../components/zoned-date-picker';

export interface FilterSettings {
  minDate: Date | null;
  maxDate: Date | null;
  restrictToFy: boolean;
  filterOnSelect: boolean;
}

export interface FilterMenuProps {
  open: boolean;
  onClose: () => void;
  onChange: (changes: FilterSettings) => void;
  filterSettings: FilterSettings;
  anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
  organization: Organization | null;
}

export function FilterMenu({ open, onClose, onChange, filterSettings, anchorEl, organization }: FilterMenuProps) {
  const defaultSettings = useRef(filterSettings);
  const theme = useTheme();

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onClose();
        }
      }}
    >
      {organization && (
        <Stack
          sx={{
            margin: theme.spacing(5),
          }}
        >
          <Typography variant='h4'>Filter Settings</Typography>
          <FormGroup>
            <Stack spacing={2}>
              <FormLabel>Date</FormLabel>
              <ZonedDatePicker
                inputTimeZone={organization.timeZone}
                label='Min.'
                value={filterSettings.minDate}
                onChange={(newDate) => {
                  onChange({
                    ...filterSettings,
                    minDate: newDate,
                  });
                }}
              />
              <ZonedDatePicker
                inputTimeZone={organization.timeZone}
                label='Max.'
                value={filterSettings.maxDate}
                onChange={(newDate) => {
                  onChange({
                    ...filterSettings,
                    maxDate: newDate,
                  });
                }}
              />
            </Stack>
          </FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterSettings.restrictToFy}
                onChange={(e) => {
                  onChange({
                    ...filterSettings,
                    restrictToFy: e.target.checked,
                  });
                }}
              />
            }
            label='Restrict to FY'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterSettings.filterOnSelect}
                onChange={(e) => {
                  onChange({
                    ...filterSettings,
                    filterOnSelect: e.target.checked,
                  });
                }}
              />
            }
            label='Filter on Select'
          />
          <Button variant='outlined' color='primary' onClick={() => onChange(defaultSettings.current)}>
            Clear
          </Button>
        </Stack>
      )}
    </Menu>
  );
}
