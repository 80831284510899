import { CircularProgress, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { formatInTimeZone } from 'date-fns-tz';
import { Copy, CopySuccess, Eye, Warning2 } from 'iconsax-react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account, StandardAccounts } from '../../../api';
import { ReconciliationContext } from './reconciliation-context';

export function ReconciliationAccountOverview() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { accounts, reconciliations, organization } = useContext(ReconciliationContext);

  const reconciliationByAccountId = useMemo(() => {
    if (!reconciliations) {
      return null;
    }

    return reconciliations.reduce(
      (map, current) => {
        map[current.accountId] = current;
        return map;
      },
      {} as {
        [accountId: string]: {
          accountId: string;
          journalBalance: string;
          checkpointBalance: string | null;
          difference: string | null;
          lastCheckpointDate: Date | null;
          lastReconciledCheckpointDate: Date | null;
          issues: {
            unreconciled?: boolean;
            needsCheckpoint?: boolean;
          };
        };
      }
    );
  }, [reconciliations]);

  const cashAndCreditAccounts = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.filter((a) =>
      [StandardAccounts.CASH, StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS, StandardAccounts.LINE_OF_CREDIT].includes(a.standardAccount)
    );
  }, [accounts]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const account = params.row as Account;

        return (
          <Tooltip title={account.id}>
            <span>
              <IconButton onClick={() => copy(account.id)}>
                {idCopied === account.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      display: 'flex',
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      display: 'flex',
      field: 'checkpointBalance',
      headerName: 'Checkpoint Balance',
      headerAlign: 'right',
      minWidth: 128,
      align: 'right',
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        if (reconciliation.checkpointBalance) {
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency || 'CAD' });

          return amountFormatter.format(parseFloat(reconciliation.checkpointBalance));
        } else {
          return 'N/A';
        }
      },
    },
    {
      display: 'flex',
      field: 'journalBalance',
      headerName: 'Journal Balance',
      headerAlign: 'right',
      minWidth: 128,
      align: 'right',
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency || 'CAD' });

        return amountFormatter.format(parseFloat(reconciliation.journalBalance));
      },
    },
    {
      display: 'flex',
      field: 'difference',
      headerName: 'Difference',
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        if (reconciliation.difference) {
          const difference = parseFloat(reconciliation.difference);

          if (!difference) {
            return null;
          }

          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: account.externalCurrency || 'CAD' });

          return (
            <span style={{ color: theme.palette.warning.main }}>
              {difference >= 0 ? '+' : undefined}
              {amountFormatter.format(difference)}
            </span>
          );
        } else {
          return 'N/A';
        }
      },
    },
    {
      display: 'flex',
      field: 'lastCheckpoint',
      headerName: 'Last Checkpoint',
      minWidth: 128,
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        if (reconciliation.lastCheckpointDate) {
          return formatInTimeZone(reconciliation.lastCheckpointDate, organization?.timeZone || 'Etc/UTC', 'MMM d, yyyy');
        } else {
          return 'Never';
        }
      },
    },
    {
      display: 'flex',
      field: 'lastReconciledCheckpoint',
      headerName: 'Last Reconciled Checkpoint',
      minWidth: 128,
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        if (reconciliation.lastReconciledCheckpointDate) {
          return formatInTimeZone(reconciliation.lastReconciledCheckpointDate, organization?.timeZone || 'Etc/UTC', 'MMM d, yyyy');
        } else {
          return 'Never';
        }
      },
    },
    {
      display: 'flex',
      field: 'issues',
      headerName: 'Issues',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const account = params.row as Account;

        if (!reconciliationByAccountId) {
          return <CircularProgress size='1rem' />;
        }

        if (!reconciliationByAccountId[account.id]) {
          return null;
        }

        const reconciliation = reconciliationByAccountId[account.id];

        if (!Object.keys(reconciliation.issues).length) {
          return null;
        }

        const issues = [];
        if (reconciliation.issues.unreconciled) {
          issues.push('Unreconciled');
        }
        if (reconciliation.issues.needsCheckpoint) {
          issues.push('Needs checkpoint');
        }

        return (
          <Tooltip
            title={
              <>
                {issues.map((i) => (
                  <span
                    key={i}
                    style={{
                      display: 'block',
                    }}
                  >
                    {i}
                  </span>
                ))}
              </>
            }
            placement='left'
          >
            <Warning2 color={theme.palette.warning.main} />
          </Tooltip>
        );
      },
    },
    {
      display: 'flex',
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const account = params.row as Account;

        return (
          <Stack direction='row'>
            <Tooltip title='View'>
              <span>
                <IconButton onClick={() => navigate(`accounts/${account.id}`)}>
                  <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack style={{ flex: 1 }}>
      <Typography variant='h4'>Cash & Credit Accounts</Typography>
      <DataGrid loading={!cashAndCreditAccounts} rows={cashAndCreditAccounts || []} columns={columns} pageSizeOptions={[100]} />
    </Stack>
  );
}
