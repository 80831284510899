import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { Copy, CopySuccess, Eye } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Account,
  AnnualCalculations,
  Document,
  documentTotal,
  Journal,
  JournalEntry,
  MonthlyCalculations,
  Organization,
  OrganizationType,
  Transaction,
  useAdmin,
} from '../../../api';
import { AdminJournalSelect, AdminOrganizationSelect, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { formatZonedTime, parseDateStringAsTimeZone } from '../../../utils/date-utils';
import { useFormState } from '../../../utils/useFormState';

const SimpleTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px;

  > thead {
    > tr {
      > th {
        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > th:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > tr:first-child {
      > th {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > :first-child {
        border-top-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
        border-top-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }

    th {
      font-weight: 500;
    }
  }

  > tbody {
    > tr {
      > td {
        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > td:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > :last-child {
      > :first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-bottom-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }
  }
`;

enum Period {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

const monthMap: Record<string, number> = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const useAdminData = (selectedOrg: Organization | null, selectedJournal: Journal | null, selectedMonth: string | null, period: Period) => {
  const { fetchOrganizations, fetchJournals, fetchJournalAccounts, journals, journalAccounts, organizations, fetchCalculations, calculations } =
    useAdmin();
  const year =
    selectedOrg && selectedJournal && journals[selectedOrg.id!] && journals[selectedOrg.id!].find((j) => j.id === selectedJournal.id)
      ? selectedJournal.fy
      : null;
  const month = period === Period.MONTHLY ? selectedMonth : null;

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    fetchJournals(selectedOrg.id!).catch((e) => {
      throw e;
    });
  }, [fetchJournals, selectedOrg]);

  useEffect(() => {
    if (!selectedOrg || !year) {
      return;
    }

    fetchCalculations(selectedOrg.id!, year, month ? monthMap[month] : null).catch((e) => {
      throw e;
    });
  }, [fetchCalculations, selectedOrg, month, year]);

  const orgJournals = useMemo(() => {
    if (!selectedOrg) {
      return [];
    }

    return journals[selectedOrg.id!] || [];
  }, [selectedOrg, journals]);

  useEffect(() => {
    if (!orgJournals.length) {
      return;
    }

    const accountPromises = [];
    for (const journal of orgJournals) {
      accountPromises.push(fetchJournalAccounts(journal.id));
    }

    Promise.all(accountPromises).catch((e) => {
      throw e;
    });
  }, [orgJournals, fetchJournalAccounts]);

  const selectedJournalAccounts = useMemo(() => {
    if (!journalAccounts || !selectedJournal) {
      return null;
    }

    return journalAccounts[selectedJournal.id] || [];
  }, [journalAccounts, selectedJournal]);

  const selectedPeriodCalculations = useMemo<AnnualCalculations | MonthlyCalculations | null>(() => {
    if (year && selectedOrg?.id && calculations[selectedOrg.id]) {
      if (month) {
        return calculations[selectedOrg.id].monthly[`${year}-${monthMap[month]}`] || null;
      } else {
        return calculations[selectedOrg.id].annual[year] || null;
      }
    } else {
      return null;
    }
  }, [selectedOrg, calculations, year, month]);

  return {
    organizations,
    journals: orgJournals,
    accounts: selectedJournalAccounts,
    calculations: selectedPeriodCalculations,
  };
};

function Insight({ label, value }: { label: string; value: string | null }) {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant='h5'>{label}</Typography>
      {value ? <Typography>{value}</Typography> : <Skeleton width={theme.spacing(24)} />}
    </Box>
  );
}

function generateOrderedMonths(endMonth: number) {
  const months: number[] = [];

  // Add months from (endMonth + 1) to 12
  for (let i = endMonth + 1; i <= 12; i++) {
    months.push(i);
  }

  // Add months from 1 to endMonth
  for (let i = 1; i <= endMonth; i++) {
    months.push(i);
  }

  return months;
}

function MonthDropdown({
  selectedOrganization,
  selectedMonth,
  setSelectedMonth,
  months,
}: {
  selectedOrganization: Organization | null;
  selectedMonth: string;
  setSelectedMonth: (month: string) => void;
  months: string[];
}) {
  if (!selectedOrganization) {
    return <Skeleton />;
  }

  return (
    <FormControl style={{ minWidth: 200 }} size='small'>
      <InputLabel id='month-select-label'>Month</InputLabel>
      <Select
        label='Month'
        labelId='month-select-label'
        autoWidth
        value={selectedMonth}
        onChange={(event) => {
          setSelectedMonth(event.target.value);
        }}
      >
        {generateOrderedMonths(selectedOrganization.fyEndMonth).map((monthIdx) => {
          const month = months[monthIdx - 1];

          return (
            <MenuItem key={month} value={month} style={{ minWidth: 200 }}>
              {month}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function currencyFormatter(currency: string, formatterCache: { [currency: string]: Intl.NumberFormat }) {
  if (!formatterCache[currency]) {
    formatterCache[currency] = new Intl.NumberFormat('en-CA', { style: 'currency', currency });
  }

  return formatterCache[currency];
}

function ExtremeTable({
  loading,
  journal,
  organization,
  info,
  mode,
}: {
  loading: boolean;
  organization: Organization | null;
  journal: Journal | null;
  info: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  mode: 'expenses' | 'sales';
}) {
  const theme = useTheme();

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const currencyFormatters = {} as { [currency: string]: Intl.NumberFormat };

  let date: string | null = null;
  let name: string | null = null;
  let amount: string | null = null;
  let percentage: string | null = null;
  let idLabel: string | null = null;
  let id: string | null = null;
  let link: string | null = null;
  if (!loading && journal && organization) {
    date = 'N/A';
    name = 'N/A';
    amount = 'None';
    percentage = 'N/A';
    idLabel = 'N/A';
    id = 'N/A';

    if (info?.transaction) {
      date = formatInTimeZone(info.transaction.date, organization.timeZone, 'MMM d, yyyy');

      name = info.transaction.name;

      amount = `${amountFormatter.format(parseFloat(info.amount))}${
        info.transaction.isoCurrencyCode !== journal.currency
          ? ` (${currencyFormatter(info.transaction.isoCurrencyCode, currencyFormatters).format(Math.abs(parseFloat(info.transaction.amount)))})`
          : ''
      }`;

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'TID';

      id = info.transaction.id;

      link = `/admin/transactions?tab=ALL&transactionIds=${id}`;
    } else if (info?.document) {
      date = formatInTimeZone(info.document.date!, organization.timeZone, 'MMM d, yyyy');

      name = info.document.merchantName;

      amount = `${amountFormatter.format(parseFloat(info.amount))}${
        info.document.currency !== journal.currency
          ? ` (${currencyFormatter(info.document.currency, currencyFormatters).format(Math.abs(documentTotal(info.document)))})`
          : ''
      }`;

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'DID';

      id = info.document.id;

      link = `/admin/documents?tab=ALL&documentIds=${id}`;
    } else if (info?.journalEntry) {
      date = formatZonedTime(
        parseDateStringAsTimeZone(info.journalEntry.date, 'yyyy-MM-dd', organization.timeZone),
        'MMM d, yyyy',
        organization.timeZone
      );

      name = info.journalEntry.memo;

      amount = amountFormatter.format(parseFloat(info.amount));

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'JEID';

      id = info.journalEntry.id;

      link = `/admin/journals?journalEntryIds=${id}`;
    }
  }

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>{idLabel}</th>
          <th>Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Percentage of Overall {mode === 'expenses' ? 'Expenses' : 'Sales'}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        )}
        {!loading && (
          <tr>
            <td align='center'>
              <Tooltip title={id}>
                <span>
                  <IconButton onClick={() => copy(id!)}>
                    {idCopied === id ? (
                      <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    ) : (
                      <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </td>

            <td>{date}</td>

            <td>{name}</td>

            <td>{amount}</td>

            <td>{percentage}</td>

            <td align='center'>
              <Tooltip title='View'>
                <span>
                  <IconButton
                    onClick={() => {
                      window.open(link!, '_blank');
                    }}
                  >
                    <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>
            </td>
          </tr>
        )}
      </tbody>
    </SimpleTable>
  );
}

function TargetCalculator({ loading, totalSales }: { loading: boolean; totalSales: string | null }) {
  const [annualTarget, setAnnualTarget, annualTargetTouched, setAnnualTargetTouched] = useFormState('');

  const annualTargetValid = useMemo(() => {
    return !isNaN(parseFloat(annualTarget));
  }, [annualTarget]);

  const targetProgress = useMemo(() => {
    if (!totalSales || !annualTargetValid) {
      return null;
    }

    return parseFloat(totalSales) / parseFloat(annualTarget);
  }, [annualTarget, annualTargetValid, totalSales]);

  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <Stack direction='row'>
      <FormControl error={annualTargetTouched && !annualTargetValid}>
        <TextField
          label='Annual Target'
          placeholder='Annual Target...'
          value={annualTarget}
          disabled={loading}
          onBlur={() => setAnnualTargetTouched(true)}
          onChange={(e) => setAnnualTarget(e.target.value)}
        />
        <FormHelperText>{annualTargetTouched && !annualTargetValid && 'Invalid value'}</FormHelperText>
      </FormControl>

      <TextField
        label='Monthly Target Progress'
        value={targetProgress !== null ? percentFormatter.format(targetProgress) : '-'}
        disabled={loading}
        InputProps={{ readOnly: true, disableUnderline: true }}
        onChange={(e) => setAnnualTarget(e.target.value)}
      />
    </Stack>
  );
}

function CashBalanceTable({
  journal,
  calculations,
  accountsById,
  mode,
}: {
  journal: Journal | null;
  calculations: MonthlyCalculations;
  accountsById: { [accountId: string]: Account };
  mode: 'monthly';
}): React.ReactNode;

function CashBalanceTable({
  journal,
  calculations,
  accountsById,
  mode,
}: {
  journal: Journal | null;
  calculations: AnnualCalculations;
  accountsById: { [accountId: string]: Account };
  mode: 'annual';
}): React.ReactNode;

function CashBalanceTable({
  journal,
  calculations,
  accountsById,
  mode,
}: {
  journal: Journal | null;
  calculations: MonthlyCalculations | AnnualCalculations;
  accountsById: { [accountId: string]: Account };
  mode: 'annual' | 'monthly';
}) {
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>Account</th>
          <th>{mode === 'monthly' ? 'Balance Last Month' : 'Balance Last FY'}</th>
          <th>Balance</th>
          <th>Growth</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(calculations.startup!.cashBalances)
          .sort(([aAccountId, _aBalance], [bAccountId, _bBalance]) => {
            const aAccount = accountsById[aAccountId];
            const bAccount = accountsById[bAccountId];

            return aAccount.name.localeCompare(bAccount.name);
          })
          .map(([accountId, balance]) => {
            const account = accountsById[accountId];

            const prevBalance =
              mode === 'monthly'
                ? amountFormatter.format(
                    parseFloat(
                      (
                        balance as {
                          balance: string;
                          previousMonthBalance: string;
                          growth: number | string;
                        }
                      ).previousMonthBalance
                    )
                  )
                : amountFormatter.format(
                    parseFloat(
                      (
                        balance as {
                          balance: string;
                          previousFyBalance: string;
                          growth: number | string;
                        }
                      ).previousFyBalance
                    )
                  );

            const currentBalance = amountFormatter.format(
              parseFloat(
                (
                  balance as {
                    balance: string;
                  }
                ).balance
              )
            );

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            const growth = typeof balance.growth === 'number' ? percentFormatter.format(balance.growth) : balance.growth;

            return (
              <tr key={accountId}>
                <td>{account.name}</td>
                <td>{prevBalance}</td>
                <td>{currentBalance}</td>
                <td>{growth}</td>
              </tr>
            );
          })}
      </tbody>
    </SimpleTable>
  );
}

function BurnRateInsight({ calculations, journal }: { calculations: MonthlyCalculations | AnnualCalculations | null; journal: Journal | null }) {
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal?.currency || 'CAD' });

  let numberAmount: number | null = null;
  let label: string;
  if (!calculations) {
    label = 'Burn Rate';
  } else {
    const amount = parseFloat(calculations.startup!.burnRate);

    if (amount >= 0) {
      label = 'Burn Rate';
    } else {
      label = 'Cash Accumulation Rate';
    }

    numberAmount = Math.abs(amount);
  }

  return <Insight label={label} value={numberAmount !== null ? `${amountFormatter.format(numberAmount)} / month` : null} />;
}

function RunwayInsight({ calculations }: { calculations: MonthlyCalculations | AnnualCalculations | null }) {
  const formatter = new Intl.NumberFormat('en-CA', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return <Insight label='Runway' value={calculations ? `${formatter.format(parseFloat(calculations.startup!.runway))} months` : null} />;
}

function MonthlyCalculationsView({
  organization,
  calculations,
  accounts,
  selectedJournal,
  style,
}: {
  organization: Organization | null;
  calculations: MonthlyCalculations | null;
  accounts: Account[] | null;
  selectedJournal: Journal | null;
  style?: CSSProperties;
}) {
  const theme = useTheme();
  const currencyFormatters = {} as { [currency: string]: Intl.NumberFormat };
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: selectedJournal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const accountsById = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [accountId: string]: Account }
    );
  }, [accounts]);

  return (
    <Stack style={style}>
      <Typography variant='h4'>Revenue</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Revenue' value={calculations ? amountFormatter.format(parseFloat(calculations.general.totalRevenue)) : null} />

        <Insight label='Total Sales' value={calculations ? amountFormatter.format(parseFloat(calculations.general.totalSales)) : null} />

        <Typography variant='h5'>Sales Target</Typography>
        <TargetCalculator loading={!calculations} totalSales={calculations?.general.totalSales || null} />

        <Typography variant='h5'>Largest Sale</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={organization}
          journal={selectedJournal}
          info={calculations?.general.largestSale || null}
          mode='sales'
        />

        <Typography variant='h5'>Smallest Sale</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={organization}
          journal={selectedJournal}
          info={calculations?.general.smallestSale || null}
          mode='sales'
        />

        <Typography variant='h5'>Repeat Clients</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Client</th>
                <th># Invoices This Month</th>
                <th># Invoices YTD</th>
              </tr>
            </thead>
            <tbody>
              {!Object.keys(calculations.general.repeatClients).length && (
                <tr>
                  <td colSpan={3} align='center'>
                    None
                  </td>
                </tr>
              )}
              {Object.entries(calculations.general.repeatClients).map(([client, count]) => (
                <tr key={client}>
                  <td>{client}</td>
                  <td>{count.monthCount}</td>
                  <td>{count.annualCount}</td>
                </tr>
              ))}
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}

        <Typography variant='h5'>Sales by Client</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Client</th>
                <th>Total Sales</th>
              </tr>
            </thead>
            <tbody>
              {!Object.keys(calculations.general.salesByClient).length && (
                <tr>
                  <td colSpan={2} align='center'>
                    None
                  </td>
                </tr>
              )}
              {Object.entries(calculations.general.salesByClient)
                .sort(([aClient, _aT], [bClient, _bT]) => {
                  if (aClient === 'none') {
                    return 1;
                  }

                  if (bClient === 'none') {
                    return -1;
                  }

                  return aClient.localeCompare(bClient);
                })
                .map(([client, total]) => (
                  <tr key={client}>
                    <td>{client === 'none' ? 'None' : client}</td>
                    <td>{amountFormatter.format(parseFloat(total))}</td>
                  </tr>
                ))}
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}
      </Stack>

      <Typography variant='h4'>Expenses</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Expenses' value={calculations ? amountFormatter.format(parseFloat(calculations.general.totalExpenses)) : null} />

        <Typography variant='h5'>Largest Expense</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={organization}
          journal={selectedJournal}
          info={calculations?.general.largestExpense || null}
          mode='expenses'
        />

        <Typography variant='h5'>Smallest Expense</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={organization}
          journal={selectedJournal}
          info={calculations?.general.smallestExpense || null}
          mode='expenses'
        />

        <Typography variant='h5'>Expense Breakdown</Typography>
        <SimpleTable>
          <thead>
            <tr>
              <th>Account</th>
              <th>Amount</th>
              <th>Percentage of Total</th>
              <th>Vs. Last Month</th>
            </tr>
          </thead>
          <tbody>
            {(!calculations || !accountsById) && (
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            )}
            {calculations && !Object.keys(calculations.general.expenseTotals).length && (
              <tr>
                <td align='center' colSpan={4}>
                  No expenses
                </td>
              </tr>
            )}
            {calculations &&
              accountsById &&
              Object.entries(calculations.general.expenseTotals)
                .sort(([_aAccountId, aTotal], [_bAccountId, bTotal]) => {
                  return parseFloat(bTotal.amount) - parseFloat(aTotal.amount);
                })
                .map(([accountId, total]) => (
                  <tr key={accountId}>
                    <td>{accountsById[accountId].name}</td>
                    <td>{amountFormatter.format(parseFloat(total.amount))}</td>
                    <td>{percentFormatter.format(total.percent)}</td>
                    <td>
                      {calculations.general.expenseGrowth[accountId] === 'Infinity' ||
                      calculations.general.expenseGrowth[accountId] === 'Negative Infinity'
                        ? calculations.general.expenseGrowth[accountId]
                        : percentFormatter.format(parseFloat(calculations.general.expenseGrowth[accountId]))}
                    </td>
                  </tr>
                ))}
          </tbody>
        </SimpleTable>
      </Stack>

      <Typography variant='h4'>Income</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Net Income' value={calculations ? amountFormatter.format(parseFloat(calculations.general.netIncome)) : null} />
      </Stack>

      <Typography variant='h4'>Tax</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight
          label={`This Month's Estimated GST/HST Owing`}
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.gstHstOwing)) : null}
        />

        <Insight
          label={`This Month's Estimated Income Tax Owing`}
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.incomeTaxOwing)) : null}
        />
      </Stack>

      {organization?.type === OrganizationType.STARTUP && (
        <>
          <Typography variant='h4'>Startup</Typography>
          <Stack paddingLeft={theme.spacing(5)}>
            <Stack direction='row'>
              <Insight label='Total Cash' value={calculations ? amountFormatter.format(parseFloat(calculations.startup!.totalCash)) : null} />
              <BurnRateInsight calculations={calculations} journal={selectedJournal} />
              <RunwayInsight calculations={calculations} />
            </Stack>

            <Typography variant='h5'>Cash Balances</Typography>
            {calculations && accountsById ? (
              <CashBalanceTable journal={selectedJournal} calculations={calculations} accountsById={accountsById} mode='monthly' />
            ) : (
              <Skeleton />
            )}

            <Stack direction='row'>
              <Insight label='OpEx' value={calculations ? amountFormatter.format(parseFloat(calculations.startup!.opex)) : null} />

              <Insight
                label='OpEx as % of Revenue'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.opexAsRevenuePercentage)) : null}
              />
            </Stack>

            <Stack direction='row'>
              <Insight label='Gross Margin' value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.grossMargin)) : null} />

              <Insight
                label='Operating Margin'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.operatingMargin)) : null}
              />

              <Insight label='EBITDA Margin' value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.ebitdaMargin)) : null} />

              <Insight
                label='Net Income Margin'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.netIncomeMargin)) : null}
              />
            </Stack>
          </Stack>
        </>
      )}

      <Typography variant='h4'>Misc</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Typography variant='h5'>Active Subscriptions</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {!calculations.general.subscriptions.length && (
                <tr>
                  <td colSpan={2} align='center'>
                    No active subscriptions
                  </td>
                </tr>
              )}

              {calculations.general.subscriptions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((subscription, index) => (
                  <tr key={index}>
                    <td>{subscription.name}</td>
                    <td>{`${currencyFormatter(subscription.currency, currencyFormatters).format(parseFloat(subscription.amount))}${subscription.currency !== selectedJournal?.currency ? ` (${amountFormatter.format(parseFloat(subscription.convertedAmount))})` : ''}`}</td>
                  </tr>
                ))}
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}
      </Stack>
    </Stack>
  );
}

function ArApJournalEntryTable({
  organization,
  journal,
  journalEntries,
}: {
  organization: Organization;
  journal: Journal;
  journalEntries: JournalEntry[];
}) {
  const theme = useTheme();
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (journalEntryId: string) => {
    setIdCopied(journalEntryId);
    await navigator.clipboard.writeText(journalEntryId);
  }, []);

  const viewJournalEntry = useCallback((journalEntryId: string) => {
    const param = `journalEntryIds=${journalEntryId}`;
    window.open(`/admin/journals?${param}`, '_blank');
  }, []);

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency || 'CAD' });

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {journalEntries.map((je) => {
          const jeAmount = je.credits.reduce((sum, current) => {
            return sum + parseFloat(current.amount);
          }, 0);

          return (
            <tr key={je.id}>
              <td>
                <Tooltip title={je.id}>
                  <span>
                    <IconButton onClick={() => copy(je.id)}>
                      {idCopied === je.id ? (
                        <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                      ) : (
                        <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </td>

              <td>
                {formatInTimeZone(parseDateStringAsTimeZone(je.date, 'yyyy-MM-dd', organization.timeZone), organization.timeZone, 'MMM d, yyyy')}
              </td>

              <td>{je.memo}</td>

              <td>{amountFormatter.format(jeAmount)}</td>

              <td>
                <Tooltip title='View Journal Entry'>
                  <span>
                    <IconButton onClick={() => viewJournalEntry(je.id)}>
                      <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    </IconButton>
                  </span>
                </Tooltip>
              </td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

function ArApDocumentBreakdownTable({
  organization,
  documents,
  documentAges,
}: {
  organization: Organization;
  documents: Document[];
  documentAges: {
    [documentId: string]: number | null;
  };
}) {
  const theme = useTheme();
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const viewDocument = useCallback((documentId: string) => {
    window.open(`/admin/documents?tab=ALL&documentIds=${documentId}`, '_blank');
  }, []);

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Age</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {documents.map((d) => {
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: d.currency || 'CAD' });

          return (
            <tr key={d.id}>
              <td>
                <Tooltip title={d.id}>
                  <span>
                    <IconButton onClick={() => copy(d.id)}>
                      {idCopied === d.id ? (
                        <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                      ) : (
                        <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </td>

              <td>{d.date ? formatInTimeZone(d.date, organization.timeZone, 'MMM d, yyyy') : 'Unknown'}</td>

              <td>{d.merchantName || 'Unknown'}</td>

              <td>{d.afterTax ? amountFormatter.format(documentTotal(d)) : 'Unknown'}</td>

              <td>{documentAges[d.id] === null ? 'Paid in next FY' : `${String(documentAges[d.id])} days`}</td>

              <td>
                <Tooltip title='View Document'>
                  <span>
                    <IconButton onClick={() => viewDocument(d.id)}>
                      <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    </IconButton>
                  </span>
                </Tooltip>
              </td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

function PrepaidExpensesTable({
  organization,
  journal,
  prepaidExpenses,
}: {
  organization: Organization;
  journal: Journal;
  prepaidExpenses: Array<{
    document: Document;
    numberOfPayments: number;
    completedPayments: number;
    period: string;
  }>;
}) {
  const theme = useTheme();
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency || 'CAD' });
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>DID</th>

          <th>Date</th>

          <th>Name</th>

          <th>Amount</th>

          <th>Payments Complete</th>

          <th>Payment Period</th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {!prepaidExpenses.length && (
          <tr>
            <td colSpan={7} align='center'>
              None
            </td>
          </tr>
        )}
        {prepaidExpenses.map((exp) => (
          <tr key={exp.document.id}>
            <td align='center'>
              <Tooltip title={exp.document.id}>
                <span>
                  <IconButton onClick={() => copy(exp.document.id)}>
                    {idCopied === exp.document.id ? (
                      <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    ) : (
                      <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </td>

            <td>{exp.document.date ? formatInTimeZone(exp.document.date, organization.timeZone, 'MMM d, yyyy') : 'Unknown'}</td>

            <td>{exp.document.merchantName || 'Unknown'}</td>

            <td>{exp.document.afterTax ? amountFormatter.format(documentTotal(exp.document)) : 'Unknown'}</td>

            <td>{`${exp.completedPayments} / ${exp.numberOfPayments}`}</td>

            <td>{exp.period}</td>

            <td align='center'>
              <Tooltip title='View'>
                <span>
                  <IconButton
                    onClick={() => {
                      window.open(`/admin/documents?tab=ALL&documentIds=${exp.document.id}`, '_blank');
                    }}
                  >
                    <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>
            </td>
          </tr>
        ))}
      </tbody>
    </SimpleTable>
  );
}

function AnnualOverviewTable({
  months,
  organization,
  journal,
  totalsByMonthAndAccount,
  accountsById,
}: {
  months: string[];
  organization: Organization;
  journal: Journal;
  totalsByMonthAndAccount: {
    [month: number]: {
      [accountId: string]: string;
    };
  };
  accountsById: { [accountId: string]: Account };
}) {
  const monthIndexes = generateOrderedMonths(organization.fyEndMonth);

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency || 'CAD' });

  const byAccountAndMonth = useMemo(() => {
    const map = {} as {
      [accountId: string]: {
        [month: number]: string;
      };
    };

    for (const [month, accountTotals] of Object.entries(totalsByMonthAndAccount)) {
      for (const [accountId, amount] of Object.entries(accountTotals)) {
        if (!map[accountId]) {
          map[accountId] = {} as { [month: number]: string };
        }

        map[accountId][Number(month)] = amount;
      }
    }

    return map;
  }, [totalsByMonthAndAccount]);

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>Account</th>
          {monthIndexes.map((monthIdx) => {
            return <th key={`month-header-${monthIdx}`}>{months[monthIdx - 1]}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {!Object.entries(byAccountAndMonth).length && (
          <tr>
            <td colSpan={13} align='center'>
              None
            </td>
          </tr>
        )}
        {Object.entries(byAccountAndMonth)
          .sort(([aAccountId, _aTotal], [bAccountId, _bTotal]) =>
            accountsById[aAccountId].standardAccount.localeCompare(accountsById[bAccountId].standardAccount)
          )
          .map(([accountId, monthData]) => {
            return (
              <tr key={accountId}>
                <td>{accountsById[accountId].name}</td>

                {monthIndexes.map((monthIdx) => {
                  return (
                    <td key={`${accountId}-${monthIdx}`}>{monthData[monthIdx] ? amountFormatter.format(parseFloat(monthData[monthIdx])) : null}</td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </SimpleTable>
  );
}

function AnnualCalculationsView({
  calculations,
  selectedOrganization,
  selectedJournal,
  accounts,
  months,
  style,
}: {
  calculations: AnnualCalculations | null;
  selectedOrganization: Organization | null;
  selectedJournal: Journal | null;
  accounts: Account[] | null;
  months: string[];
  style: CSSProperties;
}) {
  const theme = useTheme();
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: selectedJournal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const currencyFormatters = {} as { [currency: string]: Intl.NumberFormat };

  const salesTotalsByMonth = useMemo(() => {
    if (!calculations?.general.salesTotalsByMonth || !calculations.general.salesGrowthByMonth) {
      return null;
    }

    const byMonth = {} as {
      [month: number]: {
        total: string;
        growth: string;
      };
    };
    for (const [month, monthlyTotal] of Object.entries(calculations.general.salesTotalsByMonth)) {
      if (!byMonth[Number(month)]) {
        byMonth[Number(month)] = {
          total: '0',
          growth: '0',
        };
      }

      byMonth[Number(month)].total = monthlyTotal;
    }
    for (const [month, percent] of Object.entries(calculations.general.salesGrowthByMonth)) {
      byMonth[Number(month)].growth = percent;
    }

    return byMonth;
  }, [calculations]);

  const salesTotalsByCurrency = useMemo(() => {
    if (!calculations?.general.salesTotalsByCurrency) {
      return null;
    }

    const byCurrency = {} as {
      [currency: string]: {
        original: string;
        converted: string;
      };
    };
    for (const [currency, currencySalesTotal] of Object.entries(calculations.general.salesTotalsByCurrency)) {
      if (!byCurrency[currency]) {
        byCurrency[currency] = {
          original: '0',
          converted: '0',
        };
      }

      byCurrency[currency] = {
        original: currencySalesTotal.original,
        converted: currencySalesTotal.converted,
      };
    }

    return byCurrency;
  }, [calculations]);

  const expenseTotalsByCurrency = useMemo(() => {
    if (!calculations?.general.expenseTotalsByCurrency) {
      return null;
    }

    const byCurrency = {} as {
      [currency: string]: {
        original: string;
        converted: string;
      };
    };
    for (const [currency, currencyExpenseTotal] of Object.entries(calculations.general.expenseTotalsByCurrency)) {
      if (!byCurrency[currency]) {
        byCurrency[currency] = {
          original: '0',
          converted: '0',
        };
      }

      byCurrency[currency] = {
        original: currencyExpenseTotal.original,
        converted: currencyExpenseTotal.converted,
      };
    }

    return byCurrency;
  }, [calculations]);

  const accountsById = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [accountId: string]: Account }
    );
  }, [accounts]);

  return (
    <Stack style={style}>
      <Typography variant='h4'>A/P & A/R Breakdown</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Stack>
          <Typography variant='h5'>A/P</Typography>

          <Stack paddingLeft={theme.spacing(5)}>
            <Insight label='Total' value={calculations ? amountFormatter.format(parseFloat(calculations.general.accountsPayable.total)) : null} />

            {selectedJournal && selectedOrganization && calculations ? (
              calculations.general.accountsPayable.documents.length ? (
                <ArApDocumentBreakdownTable
                  organization={selectedOrganization}
                  documents={calculations.general.accountsPayable.documents}
                  documentAges={calculations.general.accountsPayable.documentAges}
                />
              ) : (
                <Typography>No Outstanding Invoices</Typography>
              )
            ) : (
              <Skeleton />
            )}

            {selectedJournal && selectedOrganization && calculations ? (
              calculations.general.accountsPayable.journalEntries.length ? (
                <ArApJournalEntryTable
                  organization={selectedOrganization}
                  journal={selectedJournal}
                  journalEntries={calculations.general.accountsPayable.journalEntries}
                />
              ) : (
                <Typography>No Manual A/P Entries</Typography>
              )
            ) : (
              <Skeleton />
            )}
          </Stack>
        </Stack>

        <Stack>
          <Typography variant='h5'>A/R</Typography>

          <Stack paddingLeft={theme.spacing(5)}>
            <Insight label='Total' value={calculations ? amountFormatter.format(parseFloat(calculations.general.accountsReceivable.total)) : null} />

            {selectedJournal && selectedOrganization && calculations ? (
              calculations.general.accountsReceivable.documents.length ? (
                <ArApDocumentBreakdownTable
                  organization={selectedOrganization}
                  documents={calculations.general.accountsReceivable.documents}
                  documentAges={calculations.general.accountsReceivable.documentAges}
                />
              ) : (
                <Typography>No Outstanding Invoices</Typography>
              )
            ) : (
              <Skeleton />
            )}

            {selectedJournal && selectedOrganization && calculations ? (
              calculations.general.accountsReceivable.journalEntries.length ? (
                <ArApJournalEntryTable
                  organization={selectedOrganization}
                  journal={selectedJournal}
                  journalEntries={calculations.general.accountsReceivable.journalEntries}
                />
              ) : (
                <Typography>No Manual A/R Entries</Typography>
              )
            ) : (
              <Skeleton />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Typography variant='h4'>Revenue</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Revenue' value={calculations ? amountFormatter.format(parseFloat(calculations.general.totalRevenue)) : null} />

        <Typography variant='h5'>Revenue Overview</Typography>
        {calculations && selectedOrganization && selectedJournal && accountsById ? (
          <AnnualOverviewTable
            organization={selectedOrganization}
            journal={selectedJournal}
            months={months}
            totalsByMonthAndAccount={calculations.general.revenueTotalsByMonthAndAccount}
            accountsById={accountsById}
          />
        ) : (
          <Skeleton />
        )}

        <Insight label='Total Sales' value={calculations ? amountFormatter.format(parseFloat(calculations.general.totalSales)) : null} />

        <Typography variant='h5'>Sales Target</Typography>
        <TargetCalculator loading={!calculations} totalSales={calculations?.general.totalSales || null} />

        <Typography variant='h5'>Largest Sale</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={selectedOrganization}
          journal={selectedJournal}
          info={calculations?.general.largestSale || null}
          mode='sales'
        />

        <Typography variant='h5'>Smallest Sale</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={selectedOrganization}
          journal={selectedJournal}
          info={calculations?.general.smallestSale || null}
          mode='sales'
        />

        <Insight
          label='Monthly Sales Average'
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.monthlySalesAverage)) : null}
        />

        <Typography variant='h5'>Best Sales Month</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Month</th>
                <th>Total</th>
                <th>Vs. Average</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{months[calculations.general.bestSalesMonth.month - 1]}</td>
                <td>{amountFormatter.format(parseFloat(calculations.general.bestSalesMonth.total))}</td>
                <td>{percentFormatter.format(parseFloat(calculations.general.bestSalesMonth.percentVsAvg))}</td>
              </tr>
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}

        <Typography variant='h5'>Worst Sales Month</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Month</th>
                <th>Total</th>
                <th>Vs. Average</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{months[calculations.general.worstSalesMonth.month - 1]}</td>
                <td>{amountFormatter.format(parseFloat(calculations.general.worstSalesMonth.total))}</td>
                <td>{percentFormatter.format(parseFloat(calculations.general.worstSalesMonth.percentVsAvg))}</td>
              </tr>
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}

        <Stack>
          <Typography variant='h5'>Sales by Month</Typography>
          {salesTotalsByMonth && selectedOrganization ? (
            <SimpleTable>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Sales</th>
                  <th>Growth</th>
                </tr>
              </thead>
              <tbody>
                {generateOrderedMonths(selectedOrganization.fyEndMonth).map((monthIdx) => {
                  const monthLabel = months[monthIdx - 1];
                  const totalSales = salesTotalsByMonth[monthIdx];

                  return (
                    <tr key={`sales-by-month-${monthIdx}`}>
                      <td>{monthLabel}</td>
                      <td>{amountFormatter.format(parseFloat(totalSales.total))}</td>
                      <td>{percentFormatter.format(parseFloat(totalSales.growth))}</td>
                    </tr>
                  );
                })}
              </tbody>
            </SimpleTable>
          ) : (
            <Skeleton />
          )}
        </Stack>

        <Stack>
          <Typography variant='h5'>Sales by Currency</Typography>
          {salesTotalsByCurrency ? (
            <SimpleTable>
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Original</th>
                  <th>Converted</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(salesTotalsByCurrency).map(([currency, totalSales]) => (
                  <tr key={`sales-by-month-${currency}`}>
                    <td>{currency}</td>
                    <td>{currencyFormatter(currency, currencyFormatters).format(parseFloat(totalSales.original))}</td>
                    <td>{amountFormatter.format(parseFloat(totalSales.converted))}</td>
                  </tr>
                ))}
              </tbody>
            </SimpleTable>
          ) : (
            <Skeleton />
          )}
        </Stack>

        <Stack>
          <Typography variant='h5'>Repeat Clients</Typography>
          {calculations ? (
            <SimpleTable>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Number of Invoices</th>
                </tr>
              </thead>
              <tbody>
                {!Object.keys(calculations.general.repeatClients).length && (
                  <tr>
                    <td colSpan={2} align='center'>
                      None
                    </td>
                  </tr>
                )}
                {Object.entries(calculations.general.repeatClients).map(([client, count]) => (
                  <tr key={client}>
                    <td>{client}</td>
                    <td>{count}</td>
                  </tr>
                ))}
              </tbody>
            </SimpleTable>
          ) : (
            <Skeleton />
          )}
        </Stack>
      </Stack>

      <Typography variant='h4'>Expenses</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Typography variant='h5'>Largest Expense</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={selectedOrganization}
          journal={selectedJournal}
          info={calculations?.general.largestExpense || null}
          mode='expenses'
        />

        <Typography variant='h5'>Smallest Expense</Typography>
        <ExtremeTable
          loading={!calculations}
          organization={selectedOrganization}
          journal={selectedJournal}
          info={calculations?.general.smallestExpense || null}
          mode='expenses'
        />

        <Typography variant='h5'>Expense Overview</Typography>
        {calculations && selectedOrganization && selectedJournal && accountsById ? (
          <AnnualOverviewTable
            organization={selectedOrganization}
            journal={selectedJournal}
            months={months}
            totalsByMonthAndAccount={calculations.general.expenseTotalsByMonthAndAccount}
            accountsById={accountsById}
          />
        ) : (
          <Skeleton />
        )}

        <Typography variant='h5'>Expenses by Currency</Typography>
        {expenseTotalsByCurrency ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Currency</th>
                <th>Original</th>
                <th>Converted</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(expenseTotalsByCurrency).map(([currency, totalExpenses]) => (
                <tr key={`expenses-by-month-${currency}`}>
                  <td>{currency}</td>
                  <td>{currencyFormatter(currency, currencyFormatters).format(parseFloat(totalExpenses.original))}</td>
                  <td>{amountFormatter.format(parseFloat(totalExpenses.converted))}</td>
                </tr>
              ))}
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}

        <Typography variant='h5'>Prepaid Expenses</Typography>
        {calculations && selectedOrganization && selectedJournal ? (
          <PrepaidExpensesTable
            organization={selectedOrganization}
            journal={selectedJournal}
            prepaidExpenses={calculations?.general.prepaidExpenses}
          />
        ) : (
          <Skeleton />
        )}
      </Stack>

      <Typography variant='h4'>Income</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Net Income' value={calculations ? amountFormatter.format(parseFloat(calculations.general.netIncome)) : null} />

        <Insight label='Net Profit' value={calculations ? amountFormatter.format(parseFloat(calculations.general.netProfit)) : null} />
      </Stack>

      <Typography variant='h4'>Tax</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight
          label={`Estimated YTD GST/HST Owing`}
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.gstHstOwing)) : null}
        />

        <Insight
          label={`Estimated YTD Income Tax Owing`}
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.incomeTaxOwing)) : null}
        />
      </Stack>

      {selectedOrganization?.type === OrganizationType.STARTUP && (
        <>
          <Typography variant='h4'>Startup</Typography>
          <Stack paddingLeft={theme.spacing(5)}>
            <Insight label='Revenue Growth' value={calculations ? amountFormatter.format(parseFloat(calculations.startup!.growthRate)) : null} />

            <Stack direction='row'>
              <Insight label='Total Cash' value={calculations ? amountFormatter.format(parseFloat(calculations.startup!.totalCash)) : null} />
              <BurnRateInsight calculations={calculations} journal={selectedJournal} />
              <RunwayInsight calculations={calculations} />
            </Stack>

            <Typography variant='h5'>Cash Balances</Typography>
            {calculations && accountsById ? (
              <CashBalanceTable journal={selectedJournal} calculations={calculations} accountsById={accountsById} mode='annual' />
            ) : (
              <Skeleton />
            )}

            <Stack direction='row'>
              <Insight label='OpEx' value={calculations ? amountFormatter.format(parseFloat(calculations.startup!.opex)) : null} />

              <Insight
                label='OpEx as % of Revenue'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.opexAsRevenuePercentage)) : null}
              />
            </Stack>

            <Stack direction='row'>
              <Insight label='Gross Margin' value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.grossMargin)) : null} />

              <Insight
                label='Operating Margin'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.operatingMargin)) : null}
              />

              <Insight label='EBITDA Margin' value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.ebitdaMargin)) : null} />

              <Insight
                label='Net Income Margin'
                value={calculations ? percentFormatter.format(parseFloat(calculations.startup!.netIncomeMargin)) : null}
              />
            </Stack>
          </Stack>
        </>
      )}

      <Typography variant='h4'>Misc</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight
          label={`Average Bank Charge`}
          value={calculations ? amountFormatter.format(parseFloat(calculations.general.averageBankCharge)) : null}
        />

        <Typography variant='h5'>Subscriptions</Typography>
        {calculations ? (
          <SimpleTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {!calculations.general.subscriptions.length && (
                <tr>
                  <td colSpan={2} align='center'>
                    No subscriptions
                  </td>
                </tr>
              )}

              {calculations.general.subscriptions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((subscription, index) => (
                  <tr key={index}>
                    <td>{subscription.name}</td>
                    <td>{`${currencyFormatter(subscription.currency, currencyFormatters).format(parseFloat(subscription.amount))}${subscription.currency !== selectedJournal?.currency ? ` (${amountFormatter.format(parseFloat(subscription.convertedAmount))})` : ''}`}</td>
                  </tr>
                ))}
            </tbody>
          </SimpleTable>
        ) : (
          <Skeleton />
        )}
      </Stack>
    </Stack>
  );
}

export function AdminCalculationsPage({ ...props }) {
  const theme = useTheme();

  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentMonth = months[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [period, setPeriod] = useState(Period.ANNUAL);

  const { organizations, journals, accounts, calculations } = useAdminData(selectedOrg, selectedJournal, selectedMonth, period);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Calculations' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  let calculationsView;
  if (!selectedOrg || !selectedJournal) {
    calculationsView = (
      <Stack justifyContent='center' alignItems='center' flex={1}>
        <Typography>Please select Organization and FY</Typography>
      </Stack>
    );
  } else if (period === Period.MONTHLY) {
    calculationsView = (
      <MonthlyCalculationsView
        organization={selectedOrg}
        selectedJournal={selectedJournal}
        accounts={accounts}
        calculations={calculations as MonthlyCalculations | null}
        style={{
          overflow: 'auto',
          paddingBottom: theme.spacing(10),
          paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
        }}
      />
    );
  } else {
    calculationsView = (
      <AnnualCalculationsView
        selectedOrganization={selectedOrg}
        selectedJournal={selectedJournal}
        accounts={accounts}
        months={months}
        calculations={calculations as AnnualCalculations | null}
        style={{
          overflow: 'auto',
          paddingBottom: theme.spacing(10),
          paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
        }}
      />
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Calculations' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
                if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                  setSelectedJournal(null);
                }
              }}
            />
            <AdminJournalSelect
              journals={journals}
              organization={selectedOrg}
              selectedJournal={selectedJournal}
              onJournalChange={(j) => setSelectedJournal(j)}
            />
          </Stack>
          <Tabs
            value={period}
            onChange={(_e, newValue: Period) => {
              setPeriod(newValue);
            }}
          >
            <Tab label='Annual' value={Period.ANNUAL} />
            <Tab label='Monthly' value={Period.MONTHLY} />
          </Tabs>
          <Stack direction='row'>
            {period === Period.MONTHLY && (
              <MonthDropdown selectedOrganization={selectedOrg} months={months} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
            )}
          </Stack>

          {calculationsView}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
