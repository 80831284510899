import { Box, CircularProgress, IconButton, Link, Stack, Tooltip, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Copy, CopySuccess } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Organization, Statement, useAdmin } from '../../../api';
import { AdminOrganizationSelect, Button, PageBody, PageContainer, PageHeader, Search } from '../../../components';
import { useOneClickDataGridEditing } from '../../../utils/datagrid';
import { getFiscalYear } from '../../../utils/date-utils';
import { ImportTransactionsDialog } from '../transactions/import-transactions';

const useAdminData = (selectedOrg: Organization | null) => {
  const {
    organizations,
    journals,
    journalAccounts,
    statements,
    fetchOrganizations,
    fetchStatements,
    fetchJournals,
    fetchJournalAccounts,
    updateStatement: updateStatementApi,
  } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    fetchStatements(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchJournals(selectedOrg.id!).catch((e) => {
      throw e;
    });
  }, [selectedOrg, fetchStatements, fetchJournals]);

  useEffect(() => {
    if (!selectedOrg || !journals) {
      return;
    }

    const fy = getFiscalYear(new Date(), selectedOrg.fyEndMonth, selectedOrg.timeZone);

    const journal = (journals[selectedOrg.id!] || []).find((j) => j.fy === fy);
    if (!journal) {
      return;
    }

    fetchJournalAccounts(journal.id).catch((e) => {
      throw e;
    });
  }, [journals, selectedOrg, fetchJournalAccounts]);

  const orgStatements = useMemo(() => {
    if (!selectedOrg) {
      return [];
    }

    return statements[selectedOrg.id!] || [];
  }, [statements, selectedOrg]);

  const updateStatement = useCallback(
    async (statementId: string, changes: Partial<Statement>) => {
      if (!selectedOrg) {
        return;
      }

      await updateStatementApi(selectedOrg.id!, statementId, changes);
    },
    [selectedOrg, updateStatementApi]
  );

  const accounts = useMemo(() => {
    if (!journals || !journalAccounts || !selectedOrg) {
      return null;
    }

    const fy = getFiscalYear(new Date(), selectedOrg.fyEndMonth, selectedOrg.timeZone);

    const journal = (journals[selectedOrg.id!] || []).find((j) => j.fy === fy);
    if (!journal) {
      return;
    }

    return journalAccounts[journal.id];
  }, [journalAccounts, selectedOrg, journals]);

  return {
    organizations,
    statements: orgStatements,
    updateStatement,
    accounts,
  };
};

interface StatementTableProps {
  statements: Statement[];
  onUpdateStatement: (statementId: string, statement: Partial<Statement>) => Promise<void>;
  onImportStatement: (statement: Statement) => void;
  style?: CSSProperties;
}

function StatementTable({ statements, onUpdateStatement, onImportStatement, style }: StatementTableProps) {
  const theme = useTheme();
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const columns: GridColDef[] = [
    {
      display: 'flex',
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const document = params.row as Statement;

        return (
          <Tooltip title={document.id}>
            <span>
              <IconButton onClick={() => copy(document.id)}>
                {idCopied === document.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      display: 'flex',
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
    },
    {
      display: 'flex',
      field: 'externalAccountId',
      headerName: 'Account Number',
    },
    {
      display: 'flex',
      field: 'created',
      headerName: 'Created',
      renderCell: (params) => {
        const statement = params.row as Statement;
        return format(statement.created, 'MMM d, yyyy');
      },
    },
    {
      display: 'flex',
      field: 'file',
      headerName: 'File',
      renderCell: (params) => {
        const statement = params.row as Statement;

        const iconSrc = statement.mimeType === 'application/pdf' ? '/pdf-file-icon.svg' : '/csv.svg';

        return (
          <Box padding={theme.spacing(2)}>
            <Link href={statement.signedUrl} target='_blank'>
              <img src={iconSrc} alt={statement.fileName} width='32px' height='32px' />
            </Link>
          </Box>
        );
      },
    },
    {
      display: 'flex',
      field: 'imported',
      headerName: 'Imported',
      renderCell: (params) => {
        const statement = params.row as Statement;

        if (statement.importDate) {
          return format(statement.importDate, 'MMM d, yyyy');
        } else {
          return (
            <Button variant='contained' color='primary' onClick={() => onImportStatement(statement)}>
              Import
            </Button>
          );
        }
      },
    },
  ];

  const filteredStatements = useMemo(() => {
    if (!searchCriteria.length) {
      return statements;
    }

    return statements.filter((statement) => {
      for (const searchCriterion of searchCriteria) {
        if (searchCriterion.toLowerCase().includes(statement.externalAccountId.toLowerCase())) {
          return true;
        }

        const formattedCreatedDate = format(statement.created, 'MMM d, yyyy');
        if (formattedCreatedDate.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }
      }
    });
  }, [statements, searchCriteria]);

  const { handleCellClick, cellModesModel, handleCellModesModelChange } = useOneClickDataGridEditing();

  return (
    <Stack style={style}>
      <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} />

      <DataGrid
        style={{ flex: 1 }}
        rows={filteredStatements}
        rowHeight={64}
        columns={columns}
        pageSizeOptions={[50]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
        }}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        isCellEditable={(params) => {
          return !!params.colDef.editable;
        }}
        processRowUpdate={async (newRow, oldRow) => {
          const old = oldRow as Statement;
          const updated = newRow as Statement;

          if (updated.name && updated.name !== old.name) {
            await onUpdateStatement(updated.id, updated);
          }

          return updated;
        }}
      />
    </Stack>
  );
}

export function AdminStatementsPage({ ...props }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [importTransactionDialogOpenFor, setImportTransactionDialogOpenFor] = useState<null | Statement>(null);
  const { organizations, statements, updateStatement, accounts } = useAdminData(selectedOrg);

  const importTransactionDialogOpenForAccount = useMemo(() => {
    if (!importTransactionDialogOpenFor || !accounts) {
      return undefined;
    }

    const account = accounts.find((a) => a.externalId === importTransactionDialogOpenFor.externalAccountId);

    return account;
  }, [importTransactionDialogOpenFor, accounts]);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Statements' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Statements' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
              }}
            />
          </Stack>

          <StatementTable
            statements={statements}
            onUpdateStatement={updateStatement}
            onImportStatement={(statement) => setImportTransactionDialogOpenFor(statement)}
            style={{ flex: 1, minHeight: 0 }}
          />

          {selectedOrg && accounts && (
            <ImportTransactionsDialog
              open={!!importTransactionDialogOpenFor}
              onClose={() => {
                setImportTransactionDialogOpenFor(null);
              }}
              afterImport={() => {
                navigate(`/admin/transactions?tab=IMPORTS`);
              }}
              organization={selectedOrg}
              accounts={accounts}
              preselectAccount={importTransactionDialogOpenForAccount}
              preselectStatement={importTransactionDialogOpenFor || undefined}
            />
          )}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
