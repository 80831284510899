import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Account,
  Checkpoint,
  CreateJournalEntryArgs,
  Journal,
  JournalEntry,
  JournalEntryLineType,
  Organization,
  TopLevelAccountType,
  useAdmin,
} from '../../../api';
import { parseDateStringAsTimeZone } from '../../../utils/date-utils';
import { entryIsCurrent } from '../../../utils/journal-entry-util';
import { isExcludedTx } from './util';

export const useReconciliationData = (organization: Organization | null, journal: Journal | null, account: Account | null) => {
  const {
    fetchOrganizations,
    organizations,
    fetchJournals,
    journals,
    fetchJournalAccounts,
    journalAccounts,
    fetchJournalAccountReconciliations,
    journalAccountReconciliations,
    fetchJournalAccountCoverage,
    journalAccountCoverage,
    fetchJournalAccountCheckpoints,
    journalAccountCheckpoints,
    fetchTransactions,
    transactions,
    fetchJournalEntries,
    journalEntries,
    reconcileAccount,
    createJournalEntry,
    fetchAccountReconciliationSuggestions,
    journalAccountReconciliationSuggestions,
    createManualCheckpoint,
  } = useAdmin();

  const [toggledTransactions, setToggledTransactions] = useState<{ [txId: string]: boolean }>({});
  const [toggledOffJournalEntries, setToggledOffJournalEntries] = useState<Set<string>>(new Set());

  const [selectedCheckpoint, setSelectedCheckpoint] = useState<Checkpoint | null>(null);
  useEffect(() => {
    setSelectedCheckpoint(null);
    setToggledOffJournalEntries(new Set());
    setToggledTransactions({});
  }, [account]);

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    fetchJournals(organization.id!).catch((e) => {
      throw e;
    });

    fetchTransactions(organization.id!).catch((e) => {
      throw e;
    });
  }, [organization, fetchJournals, fetchTransactions]);

  useEffect(() => {
    if (!journal) {
      return;
    }

    fetchJournalAccounts(journal.id).catch((e) => {
      throw e;
    });

    fetchJournalAccountReconciliations(journal.id).catch((e) => {
      throw e;
    });

    fetchJournalEntries(journal.id).catch((e) => {
      throw e;
    });
  }, [journal, fetchJournalAccounts, fetchJournalAccountReconciliations, fetchJournalEntries]);

  useEffect(() => {
    if (!journal || !account) {
      return;
    }

    fetchJournalAccountCoverage(journal.id, account.id).catch((e) => {
      throw e;
    });

    fetchJournalAccountCheckpoints(journal.id, account.id).catch((e) => {
      throw e;
    });

    fetchAccountReconciliationSuggestions(journal.id, account.id, selectedCheckpoint?.id).catch((e) => {
      throw e;
    });
  }, [journal, account, selectedCheckpoint, fetchJournalAccountCoverage, fetchJournalAccountCheckpoints, fetchAccountReconciliationSuggestions]);

  const [selectedTransactions, setSelectedTransactions] = useState<GridRowSelectionModel>([]);
  const [selectedJournalEntries, setSelectedJournalEntries] = useState<GridRowSelectionModel>([]);

  const orgJournals = useMemo(() => {
    if (!organization || !journals[organization.id!]) {
      return null;
    }

    return journals[organization.id!] || [];
  }, [organization, journals]);

  const accounts = useMemo(() => {
    if (!journal) {
      return null;
    }

    return journalAccounts[journal.id] || [];
  }, [journalAccounts, journal]);

  const accountReconciliations = useMemo(() => {
    if (!journal) {
      return null;
    }

    return journalAccountReconciliations[journal.id];
  }, [journal, journalAccountReconciliations]);

  const accountCoverage = useMemo(() => {
    if (!journalAccountCoverage || !account) {
      return null;
    }

    return journalAccountCoverage[account.id];
  }, [journalAccountCoverage, account]);

  const accountCheckpoints = useMemo(() => {
    if (!journalAccountCheckpoints || !account) {
      return null;
    }

    return journalAccountCheckpoints[account.id];
  }, [journalAccountCheckpoints, account]);

  const orgTransactions = useMemo(() => {
    if (!organization) {
      return null;
    }

    return (transactions[organization.id!] || []).sort((a, b) => a.postedDate.getTime() - b.postedDate.getTime());
  }, [transactions, organization]);

  const entries = useMemo(() => {
    if (!journal) {
      return null;
    }

    return (journalEntries[journal.id] || []).sort(
      (a, b) =>
        parse(a.reconciliationDate || a.date, 'yyyy-MM-dd', new Date()).getTime() -
        parse(b.reconciliationDate || b.date, 'yyyy-MM-dd', new Date()).getTime()
    );
  }, [journalEntries, journal]);

  const checkpointTransactions = useMemo(() => {
    return orgTransactions && journal
      ? orgTransactions.filter((t) => {
          return (
            selectedCheckpoint &&
            (t.postedDate ? t.postedDate : t.date) >= journal.fyStart &&
            (t.postedDate ? t.postedDate : t.date) < selectedCheckpoint.date &&
            t.accountId === account?.externalId
          );
        })
      : null;
  }, [orgTransactions, selectedCheckpoint, account, journal]);

  const checkpointTransactionTotal = useMemo(() => {
    const selected = new Set(selectedTransactions);

    return checkpointTransactions
      ? checkpointTransactions
          .filter((t) => {
            return (!selected.size && !isExcludedTx(toggledTransactions, t)) || selected.has(t.id);
          })
          .reduce((sum, current) => {
            return sum + -1 * parseFloat(current.amount);
          }, 0)
      : null;
  }, [checkpointTransactions, toggledTransactions, selectedTransactions]);

  const checkpointEntries = useMemo(() => {
    return entries && organization
      ? entries.filter((je) => {
          const isCurrent = entryIsCurrent(je);
          if (!isCurrent) {
            return false;
          }

          const jeForAccount = je.credits.find((c) => c.accountId === account?.id) || je.debits.find((d) => d.accountId === account?.id);
          const jeDate = parseDateStringAsTimeZone(je.reconciliationDate || je.date, 'yyyy-MM-dd', organization?.timeZone);
          return selectedCheckpoint && jeDate < selectedCheckpoint.date && jeForAccount;
        })
      : null;
  }, [entries, selectedCheckpoint, account, organization]);

  const impliedEntries = useMemo(() => {
    if (!orgTransactions || !organization) {
      return [];
    }

    return orgTransactions
      .filter((t) => toggledTransactions[t.id] === true)
      .map((t) => {
        if (journal && t.isoCurrencyCode !== journal.currency && t.isoCurrencyCode !== account?.externalCurrency) {
          throw new Error(`Illegal currencies: ${t.id}`);
        }

        const je: JournalEntry & { implied: true } = {
          implied: true,
          id: uuid(),
          date: formatInTimeZone(t.date, organization.timeZone, 'yyyy-MM-dd'),
          reconciliationDate: formatInTimeZone(t.postedDate, organization.timeZone, 'yyyy-MM-dd'),
          memo: `Transaction: ${t.name}`,
          tags: [`transaction::${t.id}`, `implied`],
          debits: [
            {
              id: uuid(),
              description: null,
              accountId: parseFloat(t.amount) > 0 ? uuid() : account!.id,
              type: JournalEntryLineType.DEBIT,
              amount:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? Math.abs(parseFloat(t.amount)).toString()
                  : '0',
              foreignCurrency:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? undefined
                  : t.isoCurrencyCode,
              foreignCurrencyAmount:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? undefined
                  : Math.abs(parseFloat(t.amount)).toString(),
            },
          ],
          credits: [
            {
              id: uuid(),
              description: null,
              accountId: parseFloat(t.amount) > 0 ? account!.id : uuid(),
              type: JournalEntryLineType.CREDIT,
              amount:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? Math.abs(parseFloat(t.amount)).toString()
                  : '0',
              foreignCurrency:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? undefined
                  : t.isoCurrencyCode,
              foreignCurrencyAmount:
                !account!.externalCurrency || account?.externalCurrency === journal?.currency || t.isoCurrencyCode === journal?.currency
                  ? undefined
                  : Math.abs(parseFloat(t.amount)).toString(),
            },
          ],
          index: entries ? entries[entries.length - 1].index + 1 : 1,
        };

        return je;
      });
  }, [toggledTransactions, orgTransactions, account, journal, entries, organization]);

  const selectedEntriesTotal = useMemo(() => {
    const selected = new Set(selectedJournalEntries);

    return checkpointEntries && impliedEntries
      ? [...checkpointEntries, ...impliedEntries]
          .filter((je) => (!selected.size && !toggledOffJournalEntries.has(je.id)) || selected.has(je.id))
          .reduce((sum, current) => {
            const debitTypes = new Set([TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE]);

            const jeAmount = [...current.credits, ...current.debits].reduce((sum, line) => {
              if (line.accountId !== account?.id) {
                return sum;
              }

              return (
                sum +
                (line.type === JournalEntryLineType.DEBIT ? 1 : -1) *
                  (account?.externalCurrency && account.externalCurrency !== 'CAD'
                    ? parseFloat(line.foreignCurrencyAmount!)
                    : parseFloat(line.amount))
              );
            }, 0);

            if ((debitTypes.has(account!.topLevelType) && jeAmount >= 0) || (!debitTypes.has(account!.topLevelType) && jeAmount <= 0)) {
              return sum + Math.abs(jeAmount);
            } else {
              return sum - Math.abs(jeAmount);
            }
          }, 0)
      : null;
  }, [checkpointEntries, toggledOffJournalEntries, account, impliedEntries, selectedJournalEntries]);

  const checkpointEntriesTotal = useMemo(() => {
    return checkpointEntries && impliedEntries
      ? [...checkpointEntries, ...impliedEntries]
          .filter((je) => !toggledOffJournalEntries.has(je.id))
          .reduce((sum, current) => {
            const debitTypes = new Set([TopLevelAccountType.ASSETS, TopLevelAccountType.EXPENSES, TopLevelAccountType.REVENUE]);

            const jeAmount = [...current.credits, ...current.debits].reduce((sum, line) => {
              if (line.accountId !== account?.id) {
                return sum;
              }

              return (
                sum +
                (line.type === JournalEntryLineType.DEBIT ? 1 : -1) *
                  (account?.externalCurrency && account.externalCurrency !== 'CAD'
                    ? parseFloat(line.foreignCurrencyAmount!)
                    : parseFloat(line.amount))
              );
            }, 0);

            if ((debitTypes.has(account!.topLevelType) && jeAmount >= 0) || (!debitTypes.has(account!.topLevelType) && jeAmount <= 0)) {
              return sum + Math.abs(jeAmount);
            } else {
              return sum - Math.abs(jeAmount);
            }
          }, 0)
      : null;
  }, [checkpointEntries, toggledOffJournalEntries, account, impliedEntries]);

  const runningDifference = useMemo(() => {
    if (!selectedCheckpoint || checkpointEntriesTotal === null) {
      return null;
    }

    return checkpointEntriesTotal - parseFloat(selectedCheckpoint.balance);
  }, [checkpointEntriesTotal, selectedCheckpoint]);

  const setTransactionToggle = useCallback(
    (txId: string, on: boolean) => {
      if (!orgTransactions) {
        return;
      }

      const transaction = orgTransactions.find((t) => t.id === txId);

      setToggledTransactions((current) => {
        let value = undefined;
        if ((!transaction!.ignored && on === false) || (transaction!.ignored && on === true)) {
          value = on;
        }

        if (value === undefined) {
          const newV = {
            ...current,
          };

          delete newV[txId];

          return newV;
        } else {
          return {
            ...current,
            [txId]: value,
          };
        }
      });

      setToggledOffJournalEntries((current) => {
        if (!entries) {
          return current;
        }

        const matchingJournalEntries = entries.filter((e) => e.tags.find((t) => t === `transaction::${txId}`));
        if (on) {
          const newSet = new Set(current);
          for (const entry of matchingJournalEntries) {
            newSet.delete(entry.id);
          }
          return newSet;
        } else {
          const newSet = new Set(current);
          for (const entry of matchingJournalEntries) {
            newSet.add(entry.id);
          }
          return newSet;
        }
      });
    },
    [entries, orgTransactions]
  );

  const setJournalEntryToggle = useCallback(
    (jeId: string, on: boolean) => {
      setToggledOffJournalEntries((current) => {
        if (!entries) {
          return current;
        }

        const newSet = new Set(current);
        if (on) {
          newSet.delete(jeId);
        } else {
          newSet.add(jeId);
        }

        const entry = entries.find((e) => e.id === jeId);
        if (!entry) {
          return current;
        }

        const transactionTag = entry.tags.find((t) => t.startsWith('transaction::'));
        if (!transactionTag) {
          return current;
        }

        for (const entry of entries) {
          if (entry.tags.find((t) => t === transactionTag)) {
            if (on) {
              newSet.delete(entry.id);
            } else {
              newSet.add(entry.id);
            }
          }
        }

        return newSet;
      });

      setToggledTransactions((current) => {
        if (!entries || !orgTransactions) {
          return current;
        }

        const entry = entries.find((e) => e.id === jeId);

        if (!entry) {
          return current;
        }

        const transactionTag = entry.tags.find((t) => t.startsWith('transaction::'));
        if (!transactionTag) {
          return current;
        }

        const txId = transactionTag.split('::')[1];

        const transaction = orgTransactions.find((t) => t.id === txId);

        let value = undefined;
        if ((!transaction!.ignored && on === false) || (transaction!.ignored && on === true)) {
          value = on;
        }

        if (value === undefined) {
          const newV = {
            ...current,
          };

          delete newV[txId];

          return newV;
        } else {
          return {
            ...current,
            [txId]: value,
          };
        }
      });
    },
    [entries, orgTransactions]
  );

  const revertToggles = useCallback(() => {
    setToggledTransactions({});
    setToggledOffJournalEntries(new Set());
  }, []);

  const reconcile = useCallback(
    async (data: { ignoreTransactionIds: string[]; unignoreTransactionIds: string[]; reverseJournalEntryIds: string[] }) => {
      if (!organization || !journal || !account) {
        return;
      }

      await reconcileAccount(organization.id!, journal.id, account.id, data);
      revertToggles();
    },
    [reconcileAccount, organization, journal, account, revertToggles]
  );

  const createEntry = useCallback(
    async (entry: CreateJournalEntryArgs) => {
      if (!journal) {
        return;
      }

      await createJournalEntry(journal.id, entry);
    },
    [createJournalEntry, journal]
  );

  const reconciliationSuggestions = useMemo(() => {
    if (!account) {
      return null;
    }

    return journalAccountReconciliationSuggestions[account.id] || [];
  }, [account, journalAccountReconciliationSuggestions]);

  const createCheckpoint = useCallback(
    async (balance: number, date: string) => {
      if (!journal || !account) {
        return;
      }

      return createManualCheckpoint(journal.id, account.id, balance, date);
    },
    [createManualCheckpoint, journal, account]
  );

  return {
    organizations,
    journals: orgJournals,
    accounts: accounts,
    reconciliations: accountReconciliations,
    accountCoverage,
    accountCheckpoints,
    account,
    journal,
    organization,
    transactions: orgTransactions,
    journalEntries: entries,
    toggledTransactions,
    toggledOffJournalEntries,
    selectedCheckpoint,
    updateSelectedCheckpoint: setSelectedCheckpoint,
    checkpointTransactions,
    checkpointTransactionTotal,
    checkpointEntries,
    impliedEntries,
    checkpointEntriesTotal,
    runningDifference,
    setJournalEntryToggle,
    setTransactionToggle,
    revertToggles,
    reconcile,
    selectedTransactions,
    selectedJournalEntries,
    updateSelectedTransactions: setSelectedTransactions,
    updateSelectedJournalEntries: setSelectedJournalEntries,
    selectedEntriesTotal,
    createJournalEntry: createEntry,
    reconciliationSuggestions,
    createCheckpoint,
  };
};

export const ReconciliationContext = createContext<ReturnType<typeof useReconciliationData>>({} as ReturnType<typeof useReconciliationData>);
